import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import type { Styles } from '../../../../types/Styles.type';
import { Input, RoundedButton } from '../../../../components';
import theme from '../../../../theme';
import type { FormValues } from './types/FormValues.type';
import { validate } from './formConfig';
import type { NewProject } from '../../stepConfigs';

type Props = {
  newProject: NewProject;
  setNewProject: (project: NewProject) => void;
  onSubmit: (project: NewProject) => void;
};

export const FillProjectName: FunctionComponent<Props> = ({
  newProject,
  setNewProject,
  onSubmit: onSubmitProp,
}) => {
  const intl = useIntl();

  const onSubmit = (values: FormValues) => {
    const updatedNewProject = { ...newProject, name: values.name };
    setNewProject(updatedNewProject);
    onSubmitProp(updatedNewProject);
  };

  return (
    <div style={styles.container}>
      <Formik
        initialValues={{ name: newProject.name }}
        validate={validate}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => (
          <form style={styles.form}>
            <Input
              style={styles.firstField}
              type="text"
              name="name"
              autoFocus
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              label={intl.formatMessage({ id: 'createProject.fillName.label' })}
              error={touched.name ? errors.name : undefined}
              canHaveError
            />
            <RoundedButton
              onClick={handleSubmit}
              label={intl.formatMessage({ id: 'createProject.next' })}
              color={theme.colors.black}
              type="COLORED_BACKGROUND"
              style={styles.button}
              isDisabled={!isValid}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: theme.widths.input,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  firstField: {
    width: '100%',
  },
  button: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: 2 * theme.margin,
  },
};
