import { ERROR_MESSAGES } from '../../../../../../../libs/apollo/errors';
import { ErrorHandler } from '../../../../../../../libs/HandleErrorService';
import intl from '../../../../../../../libs/intl';

export const errorHandler: ErrorHandler = (error) => {
  if (error instanceof Error) {
    const message = error?.message || '';

    if (
      message.includes(ERROR_MESSAGES.translationFile.GIT_PATH_ALREADY_IN_USE)
    ) {
      return intl.formatMessage({
        id: 'project.manageTranslationFilesModal.errors.gitPath.alreadyInUse',
      });
    }
  }

  return null;
};
