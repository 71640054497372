import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { getAuth } from 'firebase/auth';
import fetch from 'cross-fetch';
import { PROJECT_PATH_ITEM_KEY } from 'louv-converter';
import { setContext } from '@apollo/client/link/context';

const getAuthLink = (isLiveUpdater: boolean) =>
  setContext(async (_, { headers }) => {
    const { currentUser } = getAuth();

    if (!currentUser) {
      return { headers };
    }

    const idToken = await currentUser.getIdToken(true);

    const projectPath = isLiveUpdater
      ? localStorage.getItem(PROJECT_PATH_ITEM_KEY)
      : window.location.pathname;
    const splittedProjectPath = (projectPath || '').split('/').filter(Boolean);

    const [organizationSlug, projectSlug] = splittedProjectPath;

    return {
      headers: {
        ...headers,
        ...(organizationSlug
          ? {
              organization: organizationSlug,
              ...(projectSlug ? { project: projectSlug } : {}),
            }
          : {}),
        authorization: `Bearer ${idToken}`,
      },
    };
  });

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  fetch,
});

const getLink = (isLiveUpdater: boolean) =>
  from([getAuthLink(isLiveUpdater), httpLink]);
const cache = new InMemoryCache();

export const getApolloClient = (isLiveUpdater: boolean) =>
  new ApolloClient({
    link: getLink(isLiveUpdater),
    cache,
  });
