import React, { CSSProperties, FunctionComponent } from 'react';
import { Styles } from '../../../../types/Styles.type';
import { ButtonAndListPopup } from '../../../ButtonAndListPopup/ButtonAndListPopup';
import { CountryCodeFlag } from '../CountryCodeFlag/CountryCodeFlag';
import { countriesData } from './countriesData';

type Props = {
  onSelectCountryCode: (code: string) => void;
  selectedCountryCode: string;
  style?: CSSProperties;
};

const renderCountryCodeWithCodeLabel: FunctionComponent<{ datum: string }> = ({
  datum: countryCode,
}) => (
  <CountryCodeFlag
    countryCode={countryCode}
    showCodeLabel
    style={styles.countryCodeFlag}
  />
);

const renderCountryCode: FunctionComponent<{ datum: string }> = ({
  datum: countryCode,
}) => <CountryCodeFlag countryCode={countryCode} />;

export const SelectCountryCodeButton: FunctionComponent<Props> = ({
  onSelectCountryCode,
  selectedCountryCode,
  style = {},
}) => {
  const sortedCountryCodes = countriesData.map(({ alpha2 }) => alpha2).sort();

  const selectedDatumIndex = sortedCountryCodes.findIndex(
    (code) => code === selectedCountryCode
  );

  return (
    <ButtonAndListPopup
      data={sortedCountryCodes}
      SelectedDatumComponent={renderCountryCode}
      DatumComponent={renderCountryCodeWithCodeLabel}
      onDatumClick={onSelectCountryCode}
      selectedDatumIndex={selectedDatumIndex}
      roundButtonProps={{ isRound: true }}
      modalWidth={90}
      style={style}
    />
  );
};

const styles: Styles = {
  countryCodeFlag: {
    justifyContent: 'space-between',
    flex: 1,
  },
};
