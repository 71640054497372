import React, { CSSProperties, FunctionComponent } from 'react';
import theme from '../../theme';
import { Styles } from '../../types/Styles.type';

type Props = {
  label: string;
  isChecked: boolean;
  toggleIsChecked: () => void;
  style?: CSSProperties;
};

export const CheckboxAndLabel: FunctionComponent<Props> = ({
  label,
  isChecked,
  toggleIsChecked,
  style = {},
}) => (
  <div style={{ ...style, ...styles.container }} onClick={toggleIsChecked}>
    <div style={styles.checkbox}>
      {isChecked && <div style={styles.checkboxContent} />}
    </div>
    <div style={styles.label}>{label}</div>
  </div>
);

const CHECKBOX_SIZE = 10;

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  checkbox: {
    height: CHECKBOX_SIZE,
    width: CHECKBOX_SIZE,
    borderRadius: CHECKBOX_SIZE / 2,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.margin / 2,
    marginBottom: 20,
  },
  checkboxContent: {
    height: CHECKBOX_SIZE / 2,
    width: CHECKBOX_SIZE / 2,
    borderRadius: CHECKBOX_SIZE / 4,
    backgroundColor: theme.colors.black,
  },
  label: {
    ...theme.typos.body,
    color: theme.colors.black,
    marginLeft: theme.margin,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
};
