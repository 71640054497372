import { KeyValue } from 'louv-converter';
import type { Filters } from '../../types/Filters.type';

export const filterData = ({
  data,
  filters,
}: {
  data: KeyValue;
  filters: Filters;
}): KeyValue => {
  if (!filters.key.value && !filters.value.value) {
    return data;
  }

  return Object.entries(data).reduce((filteredData, [key, value]) => {
    const containsKey =
      !filters.key.value ||
      key.toLowerCase().includes(filters.key.value.toLowerCase());
    const containsValue =
      !filters.value.value ||
      value.toLowerCase().includes(filters.value.value.toLowerCase());

    if (!containsKey || !containsValue) {
      return filteredData;
    }

    return {
      ...filteredData,
      [key]: value,
    };
  }, {});
};
