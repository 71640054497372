import React, { FunctionComponent } from 'react';
import theme from '../../../../theme';
import type { Styles } from '../../../../types/Styles.type';
import { SelectTranslationFileButton } from '../SelectTranslationFileButton/SelectTranslationFileButton';
import { SelectBranchButton } from '../SelectBranchButton/SelectBranchButton';
import { useProjectQuery } from '../../hooks/useProjectQuery';
import { SettingsButton } from '../SettingsButton/SettingsButton';
import { useIsLiveUpdaterContext } from '../../../../providers/IsLiveUpdaterProvider/IsLiveUpdaterProvider';
import { useIsUpdatePanelVisibleContext } from '../../../../providers/IsUpdatePanelVisibleProvider/IsUpdatePanelVisibleProvider';
import { ToggleIsUpdatePanelVisibleButton } from '../ToggleIsUpdatePanelVisibleButton/ToggleIsUpdatePanelVisibleButton';

export const Header: FunctionComponent = () => {
  const { project } = useProjectQuery();
  const isLiveUpdater = useIsLiveUpdaterContext();
  const { isUpdatePanelVisible } = useIsUpdatePanelVisibleContext();

  if (!project) {
    return null;
  }

  const renderLeftContainer = () => {
    if (isLiveUpdater) {
      return <div />;
    }

    return (
      <div style={styles.subcontainer}>
        <div style={styles.projectName}>{project.name}</div>
        <SettingsButton style={styles.button} />
      </div>
    );
  };

  const renderRightContainer = () => {
    if (isLiveUpdater && !isUpdatePanelVisible) {
      return <ToggleIsUpdatePanelVisibleButton />;
    }

    return (
      <div style={styles.subcontainer}>
        <SelectBranchButton />
        <SelectTranslationFileButton style={styles.button} />
        {isLiveUpdater && (
          <ToggleIsUpdatePanelVisibleButton style={styles.button} />
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      {renderLeftContainer()}
      {renderRightContainer()}
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  projectName: {
    ...theme.typos.bodyBold,
    color: theme.colors.black,
  },
  button: {
    marginLeft: 2 * theme.margin,
  },
};
