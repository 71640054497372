import { CSSProperties } from 'react';

const colors = {
  black: '#222222',
  black05: 'rgba(34, 34, 34, 0.5)',
  white: '#ffffff',
  lightGrey: '#e8f0fe',
  grey: '#cfcfcf',
  red: 'red',
};

const fontFamilies = {
  poppins: {
    fontFamily: `'Poppins', sans-serif`,
    fontWeights: {
      normal: '300',
      bold: '500',
    },
  },
};

const margin = 8;

const typos = {
  title: {
    fontFamily: fontFamilies.poppins.fontFamily,
    fontSize: 32,
    lineHeight: '110%',
    fontWeight: fontFamilies.poppins.fontWeights.bold,
  },
  body: {
    fontFamily: fontFamilies.poppins.fontFamily,
    fontSize: 16,
    lineHeight: '130%',
    fontWeight: fontFamilies.poppins.fontWeights.normal,
  },
  bodyBold: {
    fontFamily: fontFamilies.poppins.fontFamily,
    fontSize: 16,
    lineHeight: '130%',
    fontWeight: fontFamilies.poppins.fontWeights.bold,
  },
  smallBody: {
    fontFamily: fontFamilies.poppins.fontFamily,
    fontSize: 12,
    lineHeight: '130%',
    fontWeight: fontFamilies.poppins.fontWeights.normal,
  },
};

const pagePaddings = {
  vertical: 7 * margin,
  horizontal: 7 * margin,
  liveUpdater: {
    vertical: 4 * margin,
    horizontal: 4 * margin,
  },
};

const widths = {
  input: 400,
  subcontainer: 750,
};

const disabledOpacity = 0.4;

const liveUpdaterFixedStyle: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'auto',
  zIndex: 2147483647,
};

export default {
  borderRadius: 5,
  margin,
  colors,
  shadow: {
    boxShadow: `0px 4px 14px 0px rgba(0,0,0,0.1)`,
  },
  typos,
  pagePaddings,
  widths,
  disabledOpacity,
  liveUpdaterFixedStyle,
};
