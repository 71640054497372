import React, { CSSProperties, FunctionComponent } from 'react';
import sortBy from 'lodash/sortBy';
import theme from '../../../../theme';
import { Styles } from '../../../../types/Styles.type';
import { ButtonAndListPopup } from '../../../ButtonAndListPopup/ButtonAndListPopup';
import { useModal } from '../../../Modal/hooks/useModal';
import { TranslationFile } from '../../graphql/queries/project/types';
import { CountryCodeFlag } from '../CountryCodeFlag/CountryCodeFlag';
import { ManageTranslationFilesModal } from '../ManageTranslationFilesModal/ManageTranslationFilesModal';
import { useSelectedTranslationFileIdContext } from '../../providers/SelectedTranslationFileIdProvider/SelectedTranslationFileIdProvider';
import { useProjectQuery } from '../../hooks/useProjectQuery';
import { useIsLiveUpdaterContext } from '../../../../providers/IsLiveUpdaterProvider/IsLiveUpdaterProvider';

type Props = {
  style?: CSSProperties;
};

const TranslationFileCountryCodeFlag: FunctionComponent<{
  datum: TranslationFile;
}> = ({ datum: translationFile }) => (
  <CountryCodeFlag countryCode={translationFile.countryCode} />
);

const ManageTranslationFilesButton: FunctionComponent = () => (
  <div
    style={styles.addButtonContainer}
    data-testid="manage-translation-files-button"
  >
    <div style={styles.addButtonLabel}>+-</div>
  </div>
);

export const SelectTranslationFileButton: FunctionComponent<Props> = ({
  style = {},
}) => {
  const { isModalVisible, showModal, hideModal } = useModal();
  const { project } = useProjectQuery();
  const isLiveUpdater = useIsLiveUpdaterContext();
  const { selectedTranslationFileId, setSelectedTranslationFileId } =
    useSelectedTranslationFileIdContext();

  const onSelectTranslationFile = (translationFile: TranslationFile) =>
    setSelectedTranslationFileId(translationFile.id);

  if (!project) {
    return null;
  }

  const sortedTranslationFiles = sortBy(project.translationFiles, 'id');
  const selectedTranslationFileIndex = sortedTranslationFiles.findIndex(
    ({ id }) => id === selectedTranslationFileId
  );

  let listFooter;
  if (!isLiveUpdater) {
    listFooter = {
      Component: ManageTranslationFilesButton,
      onClick: showModal,
    };
  }

  return (
    <>
      {selectedTranslationFileIndex >= 0 && (
        <ButtonAndListPopup
          style={style}
          data={sortedTranslationFiles}
          DatumComponent={TranslationFileCountryCodeFlag}
          onDatumClick={onSelectTranslationFile}
          selectedDatumIndex={selectedTranslationFileIndex}
          showListBellowButton
          modalWidth={50}
          roundButtonProps={{ isRound: true }}
          listFooter={listFooter}
        />
      )}
      <ManageTranslationFilesModal
        hide={hideModal}
        isVisible={isModalVisible}
      />
    </>
  );
};

const styles: Styles = {
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  addButtonLabel: {
    ...theme.typos.bodyBold,
    color: theme.colors.black,
  },
};
