import { gql } from '@apollo/client';

export const GET_ORGANIZATION_QUERY = gql`
  query Organization($slug: String!) {
    organization(slug: $slug) {
      id
      projects {
        id
        name
        slug
      }
    }
  }
`;
