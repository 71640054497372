import React, { FunctionComponent } from 'react';
import type { CSSProperties } from 'react';
import theme from '../../theme';
import type { Styles } from '../../types/Styles.type';
import { getStyles } from './helpers/getStyles/getStyles';
import type { RoundedButtonType } from './types/RoundedButtonType.type';
import { Loader } from '../Loader/Loader';

type Props = {
  onClick: () => void;
  color: string;
  label: string;
  type: RoundedButtonType;
  isLoading?: boolean;
  isDisabled?: boolean;
  style?: CSSProperties;
  testId?: string;
};

export const RoundedButton: FunctionComponent<Props> = ({
  onClick,
  color,
  type,
  label,
  isLoading,
  isDisabled,
  testId,
  style = {},
}) => {
  const { container: containerStyle, label: labelStyle } = getStyles({
    color,
    type,
  });

  return (
    <div
      onClick={isDisabled || isLoading ? undefined : onClick}
      style={{
        ...style,
        ...styles.container,
        ...containerStyle,
        opacity: isDisabled ? theme.disabledOpacity : 1,
      }}
      data-testid={testId}
    >
      {isLoading ? (
        <Loader color={labelStyle.color} />
      ) : (
        <div style={{ ...styles.label, ...labelStyle }}>{label}</div>
      )}
    </div>
  );
};

const styles: Styles = {
  container: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: theme.borderRadius,
    borderStyle: 'solid',
    paddingLeft: 2 * theme.margin,
    paddingRight: 2 * theme.margin,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  label: {
    ...theme.typos.bodyBold,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
};
