import React, { CSSProperties, FunctionComponent } from 'react';
import { Loader } from '../Loader/Loader';
import type { Styles } from '../../types/Styles.type';

type Props = {
  testId?: string;
  style?: CSSProperties;
};

export const FullScreenLoader: FunctionComponent<Props> = ({
  testId,
  style = {},
}) => (
  <div style={{ ...style, ...styles.container }} data-testid={testId}>
    <Loader size={10} />
  </div>
);

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
};
