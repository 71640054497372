import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
} from 'react';

const IsUpdatePanelVisibleContext = createContext<{
  isUpdatePanelVisible: boolean;
  setIsUpdatePanelVisible: (isVisible: boolean) => void;
}>({
  isUpdatePanelVisible: true,
  setIsUpdatePanelVisible: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const IsUpdatePanelVisibleProvider: FunctionComponent = ({
  children,
}) => {
  const [isUpdatePanelVisible, setIsUpdatePanelVisible] =
    useState<boolean>(true);

  return (
    <IsUpdatePanelVisibleContext.Provider
      value={{
        isUpdatePanelVisible,
        setIsUpdatePanelVisible,
      }}
    >
      {children}
    </IsUpdatePanelVisibleContext.Provider>
  );
};

export const useIsUpdatePanelVisibleContext = () =>
  useContext(IsUpdatePanelVisibleContext);
