import { ERROR_MESSAGES } from '../../../../../libs/apollo/errors';
import { ErrorHandler } from '../../../../../libs/HandleErrorService';
import intl from '../../../../../libs/intl';

export const errorHandler: ErrorHandler = (error) => {
  if (error instanceof Error) {
    const message = error?.message || '';

    if (message.includes(ERROR_MESSAGES.project.PROJECT_NAME_ALREADY_IN_USE)) {
      return intl.formatMessage({ id: 'createProject.nameAlreadyInUse' });
    }
  }

  return null;
};
