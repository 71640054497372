import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PROJECT_PATH_ITEM_KEY } from 'louv-converter';
import { LIVE_UPDATER_PROJECT_PATH_PARAM } from '../../components/UpdatePanel/components/GoToLiveUpdaterButton/GoToLiveUpdaterButton';

export const useGetLiveUpdaterProjectSlug = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectPathInSearchParams = searchParams.get(
    LIVE_UPDATER_PROJECT_PATH_PARAM
  );
  const projectPathInLocalStorage = localStorage.getItem(PROJECT_PATH_ITEM_KEY);

  useEffect(() => {
    if (projectPathInSearchParams) {
      searchParams.delete(LIVE_UPDATER_PROJECT_PATH_PARAM);
      setSearchParams(searchParams);

      if (projectPathInSearchParams !== projectPathInLocalStorage) {
        localStorage.setItem(PROJECT_PATH_ITEM_KEY, projectPathInSearchParams);
        window.location.href = `${window.location.href}`;
      }
    }
  }, []);

  const [projectSlug, organizationSlug] = (projectPathInLocalStorage || '')
    .split('/')
    .filter(Boolean);

  return {
    projectSlug,
    organizationSlug,
  };
};
