import frTranslations from './fr.json';
import enTranslations from './en.json';

export const TRANSLATIONS_KEYS = ['fr', 'en'] as const;

export type TranslationsKey = typeof TRANSLATIONS_KEYS[number];

export const DEFAULT_TRANSLATIONS_KEY: TranslationsKey = 'fr';

export const translations: {
  [key in TranslationsKey]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
} = {
  [DEFAULT_TRANSLATIONS_KEY]: frTranslations,
  en: enTranslations,
};
