import { MutationTuple } from '@apollo/client';
import { useRef } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedMutate = <Data, Variables>(
  mutate: MutationTuple<Data, Variables>[0]
): MutationTuple<Data, Variables>[0] => {
  const abortController = useRef<AbortController | null>();

  const debouncedMutate = useRef(
    debounce((args) => {
      const controller = new window.AbortController();
      abortController.current = controller;

      return mutate({
        ...args,
        options: {
          context: { fetchOptions: { signal: controller.signal } },
        },
      });
    }, 1000)
  );

  const abortLatest = () =>
    abortController.current && abortController.current.abort();

  // @ts-ignore
  const newMutate = (args) => {
    abortLatest();
    return debouncedMutate.current(args);
  };

  // @ts-ignore
  return newMutate;
};
