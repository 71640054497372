import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, Link, useParams } from 'react-router-dom';
import type { Styles } from '../../types/Styles.type';
import theme from '../../theme';
import { RoundedButton } from '../RoundedButton/RoundedButton';
import { ROUTES_MAPPING } from '../../navigation/Router';
import { useLogout } from '../../libs/authentication/hooks/useLogout';
import { useUserContext } from '../../providers/UserProvider/UserProvider';

export const Header: FunctionComponent = () => {
  const intl = useIntl();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { isLoggingOut, logout } = useLogout();
  const { organizationSlug } = useParams();

  const goToRegister = () => navigate(ROUTES_MAPPING.register.path);
  const goToSignIn = () => navigate(ROUTES_MAPPING.signin.path);
  const goToDocumentation = () => navigate(ROUTES_MAPPING.documentation.path);
  const goToOrganization =
    organizationSlug &&
    (() => navigate(ROUTES_MAPPING.organization.getPath(organizationSlug)));

  return (
    <div style={styles.container}>
      <Link to="/">
        <div style={styles.logo}>LUUU</div>
      </Link>
      <div style={styles.rightContainer}>
        <RoundedButton
          onClick={goToDocumentation}
          label={intl.formatMessage({ id: 'header.documentation' })}
          color={theme.colors.black}
          type="SIMPLE"
        />
        {user ? (
          <>
            {goToOrganization && (
              <RoundedButton
                onClick={goToOrganization}
                label={intl.formatMessage({ id: 'header.projects' })}
                color={theme.colors.black}
                type="SIMPLE"
              />
            )}
            <RoundedButton
              onClick={logout}
              label={intl.formatMessage({ id: 'header.logOut' })}
              color={theme.colors.black}
              type="SIMPLE"
              isLoading={isLoggingOut}
            />
          </>
        ) : (
          <>
            <RoundedButton
              onClick={goToSignIn}
              label={intl.formatMessage({ id: 'header.signIn' })}
              color={theme.colors.black}
              type="SIMPLE"
            />
            <RoundedButton
              onClick={goToRegister}
              label={intl.formatMessage({ id: 'header.register' })}
              color={theme.colors.black}
              style={styles.registerButton}
              type="COLORED_BACKGROUND"
            />
          </>
        )}
      </div>
    </div>
  );
};

const styles: Styles = {
  container: {
    position: 'sticky',
    top: 0,
    ...theme.shadow,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    paddingLeft: theme.pagePaddings.horizontal,
    paddingRight: theme.pagePaddings.horizontal,
    backgroundColor: theme.colors.white,
    zIndex: 2,
  },
  logo: {
    ...theme.typos.title,
    color: theme.colors.black,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};
