import { useMutation } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ConfirmModal } from '../../../../components';
import HandleErrorService from '../../../../libs/HandleErrorService';
import { DELETE_PROJECT_MUTATION } from '../../graphql/mutations/deleteProject/mutation';
import { ROUTES_MAPPING } from '../../../../navigation/Router';
import { GET_ORGANIZATION_QUERY } from '../../../Organization/graphql/queries/organization/query';

type Props = {
  isVisible: boolean;
  hide: () => void;
};

export const DeleteModal: FunctionComponent<Props> = ({ isVisible, hide }) => {
  const { projectSlug, organizationSlug } = useParams() as {
    projectSlug: string;
    organizationSlug: string;
  };
  const navigate = useNavigate();
  const intl = useIntl();

  const [deleteProject, { loading: isDeletingProject }] = useMutation(
    DELETE_PROJECT_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_ORGANIZATION_QUERY,
          variables: { slug: organizationSlug },
        },
      ],
      onError: HandleErrorService.showErrorToast,
      onCompleted: () => {
        navigate(ROUTES_MAPPING.organization.getPath(organizationSlug));
        toast.success(intl.formatMessage({ id: 'editProject.deleteSuccess' }));
      },
      variables: { slug: projectSlug },
    }
  );

  return (
    <ConfirmModal
      isVisible={isVisible}
      hide={hide}
      description={intl.formatMessage({
        id: 'editProject.deleteModal.description',
      })}
      confirm={{
        onClick: deleteProject,
        isConfirming: isDeletingProject,
      }}
    />
  );
};
