import { gql } from '@apollo/client';

export const UPDATE_LIVE_URL_MUTATION = gql`
  mutation updateLiveUrl($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      liveUrl
    }
  }
`;
