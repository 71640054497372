import { gql } from '@apollo/client';

export const UPDATE_KEY_VALUE_CHANGES_MUTATION = gql`
  mutation updateKeyValueChanges($input: UpdateTranslationFileInput!) {
    updateTranslationFile(input: $input) {
      id
      keyValueChanges
    }
  }
`;
