import { Formik, FormikProps } from 'formik';
import React, {
  ChangeEvent,
  CSSProperties,
  FunctionComponent,
  useRef,
} from 'react';
import { RoundButton } from '../../../RoundButton/RoundButton';
import { Input } from '../../../Input/Input';
import theme from '../../../../theme';
import type { Styles } from '../../../../types/Styles.type';
import { TranslationFile } from '../../graphql/queries/project/types';
import { SelectCountryCodeButton } from '../SelectCountryCodeButton/SelectCountryCodeButton';
import { useValidate } from './formConfig/formConfig';
import { useTranslationFileFormConfig } from './hooks/useTranslationFileFormConfig';
import { useUpdateTranslationFile } from './hooks/useUpdateTranslationFile';
import { FormValues } from './formConfig/type';
import { useSelectedBranchContext } from '../../providers/SelectedBranchProvider/SelectedBranchProvider';

type Props = {
  translationFile?: TranslationFile;
  style?: CSSProperties;
};

export const TranslationFileForm: FunctionComponent<Props> = ({
  translationFile,
  style = {},
}) => {
  const formRef = useRef<null | FormikProps<FormValues>>(null);
  const { isUpdating, onUpdate } = useUpdateTranslationFile();
  const { selectedBranch } = useSelectedBranchContext();
  const getValidate = useValidate();
  const { initialValues, initialTouched, createOrDeleteButton, placeholder } =
    useTranslationFileFormConfig({
      translationFile,
      formRef,
    });

  if (!selectedBranch) {
    return null;
  }

  const validate = getValidate(selectedBranch);

  const onChange = async (newValues: FormValues) => {
    const newErrors = await validate(newValues);
    const newIsValid = !Object.values(newErrors).some(Boolean);
    if (newIsValid && translationFile) {
      onUpdate(newValues);
    }
  };

  return (
    <div style={{ ...style, ...styles.container }}>
      <Formik
        initialValues={initialValues}
        initialTouched={initialTouched}
        validate={validate}
        onSubmit={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
        innerRef={formRef}
        validateOnMount
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isValid,
        }) => (
          <form style={styles.form}>
            <Input
              style={styles.field}
              type="text"
              name="gitPath"
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
                onChange({ ...values, gitPath: e.target.value });
              }}
              onBlur={handleBlur}
              value={values.gitPath}
              placeholder={placeholder}
              error={touched.gitPath ? errors.gitPath : undefined}
              canHaveError
              isLoading={isUpdating}
            />
            <SelectCountryCodeButton
              selectedCountryCode={values.countryCode}
              onSelectCountryCode={(countryCode: string) => {
                setFieldValue('countryCode', countryCode);
                onChange({ ...values, countryCode });
              }}
              style={styles.roundButton}
            />
            <RoundButton
              isRound
              isDisabled={createOrDeleteButton.getIsDisabled(isValid)}
              onClick={createOrDeleteButton.getOnClick(values)}
              label={createOrDeleteButton.label}
              style={styles.roundButton}
              isLoading={createOrDeleteButton.isLoading}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  container: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
  },
  field: {
    flex: 1,
  },
  roundButton: {
    marginLeft: 2 * theme.margin,
  },
};
