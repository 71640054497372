import { ROUTES_MAPPING } from '../../../../../../navigation/Router';

export const getGithubAuthUrl = (
  organizationSlug: string,
  projectSlug: string
) =>
  `https://github.com/login/oauth/authorize?client_id=${
    process.env.REACT_APP_GITHUB_APP_CLIENT_ID
  }&redirect_uri=${window.location.origin}${ROUTES_MAPPING.project.getPath(
    organizationSlug,
    projectSlug
  )}`;
