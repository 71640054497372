import { useMutation } from '@apollo/client';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { RoundButton } from '../../../RoundButton/RoundButton';
import { getHasTranslationFileChanges } from '../../helpers/getHasTranslationFileChanges/getHasTranslationFileChanges.helper';
import { useGitAuthStateContext } from '../../providers/GitAuthStateProvider/GitAuthStateProvider';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';
import { useSelectedBranchContext } from '../../providers/SelectedBranchProvider/SelectedBranchProvider';
import { TranslationFile } from '../Footer/graphql/queries/translationFilesKeyValues/types';
import {
  CREATE_PULL_REQUEST_MUTATION,
  getCreatePullRequestMutationRefetchQueries,
} from './graphql/mutations/createPullRequest/mutation';
import {
  CreatePullRequestMutationData,
  CreatePullRequestMutationVariables,
} from './graphql/mutations/createPullRequest/types';

type Props = {
  translationFiles: TranslationFile[];
  style?: CSSProperties;
};

export const CreatePRButton: FunctionComponent<Props> = ({
  translationFiles,
  style = {},
}) => {
  const intl = useIntl();
  const { handleGitAuthError } = useGitAuthStateContext();
  const { projectSlug } = useProjectSlugContext();
  const { selectedBranch, setSelectedBranch } = useSelectedBranchContext();

  const onComplete = ({
    createPullRequest: newBranch,
  }: CreatePullRequestMutationData) => {
    if (newBranch !== selectedBranch) {
      setSelectedBranch(newBranch);
    }
    toast.success(
      intl.formatMessage({ id: 'updatePanel.submitChangesSuccess' })
    );
  };

  const [createPullRequest, { loading }] = useMutation<
    CreatePullRequestMutationData,
    CreatePullRequestMutationVariables
  >(CREATE_PULL_REQUEST_MUTATION, {
    refetchQueries: ({ data }) =>
      getCreatePullRequestMutationRefetchQueries(
        projectSlug,
        data?.createPullRequest
      ),
    onError: handleGitAuthError,
    onCompleted: onComplete,
  });

  const hasChanges = translationFiles.some(getHasTranslationFileChanges);

  if (!selectedBranch || !hasChanges) {
    return null;
  }

  const onClick = () =>
    createPullRequest({ variables: { branch: selectedBranch } });

  return (
    <RoundButton
      onClick={onClick}
      label={intl.formatMessage({ id: 'updatePanel.submitChanges' })}
      isLoading={loading}
      style={style}
    />
  );
};
