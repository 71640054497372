import { gql } from '@apollo/client';

export const GET_PROJECT_QUERY = gql`
  query Project($slug: String!) {
    project(slug: $slug) {
      id
      slug
      name
      devBranch
      translationFiles {
        id
        countryCode
        gitPath
      }
    }
  }
`;
