import React, { CSSProperties, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAPPING } from '../../../../navigation/Router';
import theme from '../../../../theme';
import { Styles } from '../../../../types/Styles.type';
import { ButtonAndListPopup } from '../../../ButtonAndListPopup/ButtonAndListPopup';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';

type Props = {
  style?: CSSProperties;
};

const Setting: FunctionComponent<{ datum: string }> = ({ datum: label }) => (
  <div style={styles.settingLabel}>{label}</div>
);

const SettingsButtonComponent: FunctionComponent = () => (
  <div style={styles.settingsLabel}>s</div>
);

export const SettingsButton: FunctionComponent<Props> = ({ style = {} }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectSlug, organizationSlug } = useProjectSlugContext();

  const goToEditProject = () =>
    navigate(ROUTES_MAPPING.editProject.getPath(organizationSlug, projectSlug));

  return (
    <ButtonAndListPopup
      style={style}
      data={[intl.formatMessage({ id: 'project.edit' })]}
      DatumComponent={Setting}
      SelectedDatumComponent={SettingsButtonComponent}
      onDatumClick={goToEditProject}
      selectedDatumIndex={0}
      showListBellowButton
      modalWidth={150}
    />
  );
};

const styles: Styles = {
  settingLabel: {
    ...theme.typos.body,
    color: theme.colors.black,
  },
  settingsLabel: {
    ...theme.typos.bodyBold,
    color: theme.colors.black,
  },
};
