import { gql } from '@apollo/client';
import { GET_TRANSLATION_FILES_KEY_VALUES_QUERY } from '../../../../Footer/graphql/queries/translationFilesKeyValues/query';
import { GET_GIT_UNMERGED_GENERATED_BRANCHES_QUERY } from '../../../../SelectBranchButton/graphql/queries/gitUnmergedGeneratedBranches/query';

export const getCreatePullRequestMutationRefetchQueries = (
  projectSlug: string,
  branch?: string
) => [
  {
    query: GET_GIT_UNMERGED_GENERATED_BRANCHES_QUERY,
    variables: { slug: projectSlug },
  },
  {
    query: GET_TRANSLATION_FILES_KEY_VALUES_QUERY,
    variables: { slug: projectSlug, branch },
  },
];

export const CREATE_PULL_REQUEST_MUTATION = gql`
  mutation createPullRequest($branch: String!) {
    createPullRequest(branch: $branch)
  }
`;
