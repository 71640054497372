import throttle from 'lodash/throttle';
import { toast } from 'react-toastify';
import type { ApolloError } from '@apollo/client';
import intl from './intl';
import { ERROR_MESSAGES as AUTH_ERROR_MESSAGES } from './authentication/errors';

export type ErrorType = Error | ApolloError;

export type ErrorHandler = (error?: ErrorType) => string | null;

export default class HandleErrorService {
  static isNoNetworkError(error?: ErrorType): boolean {
    if (!error) {
      return false;
    }

    return (
      error?.message?.includes(AUTH_ERROR_MESSAGES.common.NO_NETWORK) ||
      !!(error as ApolloError)?.networkError
    );
  }

  static isUnauthorizedError(error?: ErrorType): boolean {
    return !!(error as ApolloError)?.graphQLErrors?.some(
      (graphqlError) =>
        // @ts-ignore
        graphqlError?.extensions?.response?.error === 'Unauthorized' ||
        // @ts-ignore
        graphqlError?.extensions?.response?.message === 'Unauthorized'
    );
  }

  static sendDefault(error?: ErrorType, errorHandler?: ErrorHandler) {
    const defaultMessage = intl.formatMessage({ id: 'errors.default' });

    if (!errorHandler) {
      return defaultMessage;
    }

    const errorMessage = errorHandler(error);

    if (!errorMessage) {
      return defaultMessage;
    }

    return errorMessage;
  }

  static getErrorMessage(error?: ErrorType, errorHandler?: ErrorHandler) {
    if (HandleErrorService.isNoNetworkError(error)) {
      return intl.formatMessage({ id: 'errors.noNetwork' });
    }

    if (HandleErrorService.isUnauthorizedError(error)) {
      return intl.formatMessage({ id: 'errors.unauthorized' });
    }

    return HandleErrorService.sendDefault(error, errorHandler);
  }

  static showErrorToastThrottled(
    error?: ErrorType,
    errorHandler?: ErrorHandler
  ) {
    toast.error(HandleErrorService.getErrorMessage(error, errorHandler));
  }

  static showErrorToast = throttle(
    HandleErrorService.showErrorToastThrottled,
    2000
  );
}
