import { gql } from '@apollo/client';

export const GET_TRANSLATION_FILES_KEY_VALUES_QUERY = gql`
  query TranslationFilesKeyValues($slug: String!, $branch: String) {
    project(slug: $slug) {
      id
      translationFiles {
        id
        keyValue(branch: $branch)
        keyValueChanges
      }
    }
  }
`;
