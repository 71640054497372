import { useState } from 'react';
import type { Filters } from '../types/Filters.type';

export const useFilters = (): Filters => {
  const [keyFilterValue, setKeyFilterValue] = useState<string | undefined>(
    undefined
  );
  const [valueFilterValue, setValueFilterValue] = useState<string | undefined>(
    undefined
  );

  return {
    key: {
      value: keyFilterValue,
      setValue: setKeyFilterValue,
    },
    value: {
      value: valueFilterValue,
      setValue: setValueFilterValue,
    },
  };
};
