import { gql } from '@apollo/client';

export const GET_GIT_BRANCHES_QUERY = gql`
  query GitBranches($slug: String!) {
    project(slug: $slug) {
      id
      gitBranches
    }
  }
`;
