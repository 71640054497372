import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import type { Styles } from '../../types/Styles.type';
import theme from '../../theme';
import { RoundedButton } from '../RoundedButton/RoundedButton';
import { Modal } from '../Modal/Modal';

type Props = {
  isVisible: boolean;
  hide: () => void;
  description: string;
  confirm: {
    onClick: () => void;
    isConfirming?: boolean;
  };
};

export const ConfirmModal: FunctionComponent<Props> = ({
  isVisible,
  hide,
  description,
  confirm,
}) => {
  const intl = useIntl();

  return (
    <Modal isVisible={isVisible} hide={hide}>
      <div style={styles.description}>{description}</div>
      <div style={styles.bottomButtons}>
        <RoundedButton
          onClick={confirm.onClick}
          label={intl.formatMessage({ id: 'confirm' })}
          type="WITH_BORDER"
          color={theme.colors.black}
          style={styles.bottomButton}
          isLoading={confirm.isConfirming}
          testId="confirm-modal-confirm-button"
        />
        <RoundedButton
          onClick={hide}
          label={intl.formatMessage({ id: 'cancel' })}
          type="COLORED_BACKGROUND"
          color={theme.colors.black}
          style={{ ...styles.bottomButton, ...styles.cancelButton }}
          testId="confirm-modal-cancel-button"
        />
      </div>
    </Modal>
  );
};

const styles: Styles = {
  description: {
    ...theme.typos.body,
    color: theme.colors.black,
    textAlign: 'center',
  },
  bottomButtons: {
    marginTop: 8 * theme.margin,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  bottomButton: {
    flex: 1,
  },
  cancelButton: {
    marginLeft: 2 * theme.margin,
  },
};
