import React, { FunctionComponent, CSSProperties, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import type { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';
import type {
  Filters as FiltersType,
  Filter as FilterType,
} from './types/Filters.type';
import { Input } from '../../../Input/Input';

type Props = {
  style?: CSSProperties;
  filters: FiltersType;
};

export const INITIAL_WIDTHS = {
  key: '30%',
  value: '70%',
};

export const Headers: FunctionComponent<Props> = ({
  filters: { key, value },
  style = {},
}) => {
  const intl = useIntl();

  const getOnChange =
    (setValue: FilterType['setValue']) => (e: ChangeEvent<HTMLInputElement>) =>
      setValue(e.target.value || undefined);

  return (
    <div style={{ ...style, ...styles.container }}>
      <Input
        style={styles.keySearch}
        inputStyle={styles.input}
        value={key.value || ''}
        onChange={getOnChange(key.setValue)}
        placeholder={intl.formatMessage({
          id: 'project.translationsTable.column1.searchPlaceholder',
        })}
      />
      <Input
        style={styles.valueSearch}
        inputStyle={styles.input}
        value={value.value || ''}
        onChange={getOnChange(value.setValue)}
        placeholder={intl.formatMessage({
          id: 'project.translationsTable.column2.searchPlaceholder',
        })}
      />
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  keySearch: {
    width: INITIAL_WIDTHS.key,
  },
  valueSearch: {
    width: INITIAL_WIDTHS.value,
  },
  input: {
    backgroundColor: theme.colors.white,
  },
};
