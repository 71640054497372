import theme from '../../../../theme';
import type { RoundedButtonType } from '../../types/RoundedButtonType.type';

export const getStyles = ({
  color,
  type,
}: {
  color: string;
  type: RoundedButtonType;
}) => {
  switch (type) {
    case 'COLORED_BACKGROUND':
      return {
        label: {
          color: theme.colors.white,
        },
        container: {
          borderColor: color,
          backgroundColor: color,
        },
      };
    case 'WITH_BORDER':
      return {
        label: {
          color,
        },
        container: {
          borderColor: color,
          backgroundColor: theme.colors.white,
        },
      };
    case 'SIMPLE':
    default:
      return {
        label: {
          color,
        },
        container: {
          borderColor: theme.colors.white,
          backgroundColor: theme.colors.white,
        },
      };
  }
};
