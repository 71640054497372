import slugify from 'slugify';
import intl from '../../../../libs/intl';
import { ROUTES_MAPPING } from '../../../../navigation/Router';
import { FormValues } from './types/FormValues.type';

export const validate = (values: FormValues) => {
  const errors = {};

  if (!values.name) {
    // @ts-ignore
    errors.name = intl.formatMessage({ id: 'form.errors.required' });
  } else {
    const createProjectPaths = ROUTES_MAPPING.createProject.path.split('/');
    if (
      slugify(values.name).toLocaleLowerCase() ===
      createProjectPaths[createProjectPaths.length - 1]
    ) {
      // @ts-ignore
      errors.name = intl.formatMessage({
        id: 'createProject.fillName.reservedName',
      });
    }
  }

  return errors;
};
