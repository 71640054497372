import React, { FunctionComponent } from 'react';
import type { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';

type Props = {
  isCompleted: boolean;
  stepIndex: number;
  onClick: () => void;
  testId?: string;
};

export const StepNumber: FunctionComponent<Props> = ({
  stepIndex,
  isCompleted,
  onClick,
  testId,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        ...styles.container,
        ...(isCompleted ? styles.completedContainer : {}),
      }}
      data-testid={testId}
    >
      <div
        style={{
          ...styles.label,
          ...(isCompleted ? styles.completedLabel : {}),
        }}
      >
        {stepIndex + 1}
      </div>
    </div>
  );
};

export const STEP_NUMBER_SIZE = 40;

const styles: Styles = {
  container: {
    backgroundColor: theme.colors.white,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: '50%',
    borderColor: theme.colors.grey,
    height: STEP_NUMBER_SIZE,
    width: STEP_NUMBER_SIZE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'borderColor 1s',
    cursor: 'pointer',
  },
  completedContainer: {
    borderColor: theme.colors.black,
  },
  label: {
    ...theme.typos.bodyBold,
    color: theme.colors.grey,
    transition: 'color 1s',
  },
  completedLabel: {
    color: theme.colors.black,
  },
};
