import React, { FunctionComponent, CSSProperties, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import type { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';
import { AVAILABLE_PAGE_SIZES } from './hooks/usePagination';

type Props = {
  dataSize: number;
  pageSize: number;
  setPageSize: (size: number) => void;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  style?: CSSProperties;
};

export const Pagination: FunctionComponent<Props> = ({
  dataSize,
  pageSize,
  setPageSize,
  pageIndex,
  setPageIndex,
  style,
}) => {
  const intl = useIntl();

  const numberOfPages = Math.ceil(dataSize / pageSize);
  const canGoToNextPage = pageIndex < numberOfPages - 1;
  const canGoToPreviousPage = pageIndex > 0;

  const goToNextPage = () => {
    if (canGoToNextPage) {
      setPageIndex(pageIndex + 1);
    }
  };

  const goToPreviousPage = () => {
    if (canGoToPreviousPage) {
      setPageIndex(pageIndex - 1);
    }
  };

  const goToLastPage = () => {
    if (canGoToNextPage) {
      setPageIndex(numberOfPages - 1);
    }
  };

  const goToFirstPage = () => {
    if (canGoToPreviousPage) {
      setPageIndex(0);
    }
  };

  const onSelectPageSize = (e: ChangeEvent<HTMLSelectElement>) =>
    setPageSize(Number(e.target.value));

  return (
    <div style={{ ...style, ...styles.container }}>
      <button
        onClick={goToFirstPage}
        disabled={!canGoToPreviousPage}
        type="button"
      >
        {'<<'}
      </button>
      <button
        onClick={goToPreviousPage}
        disabled={!canGoToPreviousPage}
        type="button"
        style={styles.button}
      >
        {'<'}
      </button>
      <button
        onClick={goToNextPage}
        disabled={!canGoToNextPage}
        type="button"
        style={styles.button}
      >
        {'>'}
      </button>
      <button
        onClick={goToLastPage}
        disabled={!canGoToNextPage}
        type="button"
        style={styles.button}
      >
        {'>>'}
      </button>
      <div style={styles.pageNumber}>
        {intl.formatMessage(
          { id: 'project.translationsTable.pagination.pageNumber' },
          { pageIndex: pageIndex + 1, numberOfPages }
        )}
      </div>
      <div style={styles.rowPerPageLabel}>
        {intl.formatMessage({
          id: 'project.translationsTable.pagination.rowsPerPage',
        })}
      </div>
      <select
        value={pageSize}
        onChange={onSelectPageSize}
        style={styles.rowPerPageSelect}
      >
        {AVAILABLE_PAGE_SIZES.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginLeft: theme.margin / 2,
  },
  pageNumber: {
    ...theme.typos.body,
    color: theme.colors.black,
    marginLeft: 2 * theme.margin,
  },
  rowPerPageLabel: {
    ...theme.typos.body,
    color: theme.colors.black,
    marginLeft: 4 * theme.margin,
  },
  rowPerPageSelect: {
    ...theme.typos.body,
    color: theme.colors.black,
    marginLeft: theme.margin,
  },
};
