import { gql } from '@apollo/client';
import { GET_PROJECT_QUERY } from '../../../../../graphql/queries/project/query';

export const getCreateTranslationFileMutationRefetchQueries = (
  projectSlug: string
) => [
  {
    query: GET_PROJECT_QUERY,
    variables: { slug: projectSlug },
  },
];

export const CREATE_TRANSLATION_FILE_MUTATION = gql`
  mutation createTranslationFile($input: CreateTranslationFileInput!) {
    createTranslationFile(input: $input) {
      id
    }
  }
`;
