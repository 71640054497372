import { gql } from '@apollo/client';

export const GET_ORGANIZATIONS_QUERY = gql`
  query Organizations {
    me {
      id
      organizations {
        id
        slug
        isPersonal
      }
    }
  }
`;
