import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import { KeyValue } from 'louv-converter';
import { useGitAuthStateContext } from '../../providers/GitAuthStateProvider/GitAuthStateProvider';
import { useSelectedTranslationFileIdContext } from '../../providers/SelectedTranslationFileIdProvider/SelectedTranslationFileIdProvider';
import { getUpdatedKeyValue } from './helpers/getUpdatedKeyValue/getUpdatedKeyValue';
import { useSelectedBranchContext } from '../../providers/SelectedBranchProvider/SelectedBranchProvider';
import {
  GetTranslationFileQueryData,
  GetTranslationFileQueryVariables,
} from './graphql/queries/translationFile/types';
import { GET_TRANSLATION_FILE_QUERY } from './graphql/queries/translationFile/query';
import { FullScreenLoader } from '../../../FullScreenLoader/FullScreenLoader';
import { useUpdateKeyValueChanges } from '../../hooks/useUpdateKeyValueChanges';

export type Props = {
  TableComponent: FunctionComponent<{
    keyValue: KeyValue;
    initialKeyValue: KeyValue;
    onChange: (key: string, value: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  }>;
};

export const TableComponentWrapper: FunctionComponent<Props> = ({
  TableComponent,
}) => {
  const { selectedTranslationFileId } = useSelectedTranslationFileIdContext();
  const { selectedBranch } = useSelectedBranchContext();
  const { handleGitAuthError } = useGitAuthStateContext();
  const { updateKeyValueChanges } = useUpdateKeyValueChanges();

  const { data, loading } = useQuery<
    GetTranslationFileQueryData,
    GetTranslationFileQueryVariables
  >(GET_TRANSLATION_FILE_QUERY, {
    variables: {
      id: selectedTranslationFileId as string,
      branch: selectedBranch || undefined,
    },
    onError: handleGitAuthError,
    skip: !selectedTranslationFileId,
  });

  if (loading && !data) {
    return <FullScreenLoader />;
  }

  if (!data) {
    return null;
  }

  const { translationFile } = data;
  const { keyValue, keyValueChanges } = translationFile;

  return (
    <TableComponent
      keyValue={getUpdatedKeyValue(keyValue, keyValueChanges)}
      onChange={updateKeyValueChanges(translationFile)}
      initialKeyValue={JSON.parse(keyValue)}
    />
  );
};
