import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Formik } from 'formik';
import { useApolloClient } from '@apollo/client';
import { deleteUser, getAuth } from 'firebase/auth';
import intl from '../../libs/intl';
import type { Styles } from '../../types/Styles.type';
import { Input, RoundedButton, CheckboxAndLabel } from '../../components';
import theme from '../../theme';
import { register } from '../../libs/authentication/authentication';
import type { FormValues } from './form/type';
import HandleErrorService from '../../libs/HandleErrorService';
import { INITIAL_FORM_VALUES, useValidate } from './form/config';
import { useCheckbox } from '../../components/CheckboxAndLabel/hooks/useCheckbox';
import {
  CreateUserMutationData,
  CreateUserMutationVariables,
} from './graphql/mutations/createUser/type';
import { CREATE_USER_MUTATION } from './graphql/mutations/createUser/mutation';
import { removeSpaces } from '../../components/Input/helpers/removeSpaces/removeSpaces';
import { useUserContext } from '../../providers/UserProvider/UserProvider';

export const Register: FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isChecked, toggleIsChecked } = useCheckbox();
  const client = useApolloClient();
  const getValidate = useValidate();
  const { refreshUser } = useUserContext();

  const onRegisterClick = ({
    email,
    password,
    nickname,
    organizationName,
  }: FormValues) => {
    setIsSubmitting(true);
    return register({ email, password })
      .then(({ user: { uid } }) =>
        client.mutate<CreateUserMutationData, CreateUserMutationVariables>({
          mutation: CREATE_USER_MUTATION,
          variables: {
            input: { email, firebaseId: uid, nickname, organizationName },
          },
        })
      )
      .then(() => refreshUser())
      .catch(async (e) => {
        setIsSubmitting(false);
        HandleErrorService.showErrorToast(e);
        const user = getAuth().currentUser;
        if (user) {
          await deleteUser(user).catch(HandleErrorService.showErrorToast);
        }
      })
      .finally(() => setTimeout(() => setIsSubmitting(false), 3000));
  };

  return (
    <div style={styles.container}>
      <Formik
        initialValues={INITIAL_FORM_VALUES}
        validate={getValidate(isChecked)}
        onSubmit={onRegisterClick}
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          setFieldValue,
        }) => (
          <form style={styles.form}>
            <Input
              style={styles.firstField}
              type="email"
              name="email"
              autoFocus
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue('email', removeSpaces(e))
              }
              onBlur={handleBlur}
              value={values.email}
              label={intl.formatMessage({ id: 'form.fields.email' })}
              error={touched.email ? errors.email : undefined}
              canHaveError
            />
            <Input
              style={styles.field}
              type="text"
              name="nickname"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.nickname}
              label={intl.formatMessage({ id: 'form.fields.nickname' })}
              error={touched.nickname ? errors.nickname : undefined}
              canHaveError
            />
            <Input
              style={styles.field}
              type="password"
              name="password"
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue('password', removeSpaces(e))
              }
              onBlur={handleBlur}
              value={values.password}
              label={intl.formatMessage({ id: 'form.fields.password' })}
              error={touched.password ? errors.password : undefined}
              canHaveError
            />
            <Input
              style={styles.field}
              type="password"
              name="confirmedPassword"
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue('confirmedPassword', removeSpaces(e))
              }
              onBlur={handleBlur}
              value={values.confirmedPassword}
              label={intl.formatMessage({
                id: 'form.fields.confirmedPassword',
              })}
              error={
                touched.confirmedPassword ? errors.confirmedPassword : undefined
              }
              canHaveError
            />
            <CheckboxAndLabel
              label={intl.formatMessage({
                id: 'form.fields.createAnOrganization',
              })}
              isChecked={isChecked}
              toggleIsChecked={() => {
                toggleIsChecked();
                setFieldValue('organizationName', undefined);
              }}
              style={styles.checkboxAndLabel}
            />
            {isChecked && (
              <Input
                style={styles.field}
                type="text"
                name="organizationName"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organizationName}
                label={intl.formatMessage({
                  id: 'form.fields.organizationName',
                })}
                error={
                  touched.organizationName ? errors.organizationName : undefined
                }
                canHaveError
              />
            )}
            <RoundedButton
              onClick={handleSubmit}
              label={intl.formatMessage({ id: 'register.register' })}
              type="WITH_BORDER"
              color={theme.colors.black}
              style={styles.submitButton}
              isLoading={isSubmitting}
              isDisabled={!isValid}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: theme.widths.input,
  },
  firstField: {
    width: '100%',
  },
  field: {
    width: '100%',
    marginTop: theme.margin,
  },
  checkboxAndLabel: {
    width: '100%',
    marginTop: theme.margin,
    marginBottom: 20,
  },
  submitButton: {
    marginTop: 4 * theme.margin,
  },
};
