import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { getNodePositionStyle } from '../../helpers/getNodePositionStyle/getNodePositionStyle.helper';
import { Input as BaseInput, TEXTAREA_HEIGHT } from '../../../Input/Input';
import theme from '../../../../theme';

export type Props = {
  node: Node;
  onBlur: () => void;
  value: string;
  onChange: (value: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const Input: FunctionComponent<Props> = ({
  node,
  onBlur,
  value: initialValue,
  onChange: onChangeProp,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);

    return onChangeProp(newValue);
  };

  const positionStyle = getNodePositionStyle(node);
  if (!positionStyle) {
    return null;
  }

  const { top, left, height, width } = positionStyle;

  return (
    <BaseInput
      style={{ top, left, ...theme.liveUpdaterFixedStyle }}
      autoFocus
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      multiline
      dimensions={{
        width,
        height: height > TEXTAREA_HEIGHT ? height : TEXTAREA_HEIGHT,
      }}
    />
  );
};
