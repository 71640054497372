import { useEffect } from 'react';
import { getNodePositionStyle } from '../helpers/getNodePositionStyle/getNodePositionStyle.helper';

export const useSetSelectedNodeOnMouseEvents = (
  selectedNode: { node: Node; isEditing?: boolean } | null,
  setSelectedNode: (
    newSelectedNode: { node: Node; isEditing?: boolean } | null
  ) => void,
  translatedNodes: Node[]
) => {
  const onMouseMove = (mouseEvent: MouseEvent) => {
    if (!selectedNode || selectedNode.isEditing) {
      return;
    }

    const hoverNodePosition = getNodePositionStyle(selectedNode.node);

    if (!hoverNodePosition) {
      return;
    }

    const { top, left, height, width } = hoverNodePosition;
    const { clientX, clientY } = mouseEvent;

    if (
      clientX < left ||
      clientX > left + width ||
      clientY < top ||
      clientY > top + height
    ) {
      setSelectedNode(null);
    }
  };

  const onMouseHover = (event: Event) => {
    if (selectedNode) {
      return;
    }

    const node = event.target as Node;

    const timeout = setTimeout(() => setSelectedNode({ node }), 500);

    // @ts-ignore
    event.target.onmouseleave = () => clearTimeout(timeout); // eslint-disable-line no-param-reassign
  };

  useEffect(() => {
    translatedNodes.forEach((node) => {
      node.addEventListener('mouseover', onMouseHover);
    });

    return () => {
      translatedNodes.forEach((node) => {
        node.removeEventListener('mouseover', onMouseHover);
      });
    };
  }, [translatedNodes, onMouseHover]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);
};
