import { useMutation } from '@apollo/client';
import { FormikProps } from 'formik';
import { useEffect, MutableRefObject, useState } from 'react';
import HandleErrorService from '../../../../../libs/HandleErrorService';
import { errorHandler } from '../graphql/mutations/createTranslationFile/errorHandler';
import {
  CREATE_TRANSLATION_FILE_MUTATION,
  getCreateTranslationFileMutationRefetchQueries,
} from '../graphql/mutations/createTranslationFile/mutation';
import type {
  CreateTranslationFileMutationData,
  CreateTranslationFileMutationVariables,
} from '../graphql/mutations/createTranslationFile/types';
import {
  INITIAL_CREATE_FORM_VALUES,
  INITIAL_CREATE_FORM_TOUCHED,
} from '../formConfig/formConfig';
import { FormValues } from '../formConfig/type';
import { useProjectQuery } from '../../../hooks/useProjectQuery';

export const useCreateTranslationFile = (
  formRef: MutableRefObject<null | FormikProps<FormValues>>
) => {
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const { project } = useProjectQuery();

  const [createTranslationFile, { loading: isCreating }] = useMutation<
    CreateTranslationFileMutationData,
    CreateTranslationFileMutationVariables
  >(CREATE_TRANSLATION_FILE_MUTATION, {
    refetchQueries: getCreateTranslationFileMutationRefetchQueries(
      project?.slug || ''
    ),
    onError: (e) => HandleErrorService.showErrorToast(e, errorHandler),
    onCompleted: () => setShouldResetForm(true),
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    const resetForm = async () => {
      if (!formRef?.current) {
        return;
      }

      formRef.current.setValues(INITIAL_CREATE_FORM_VALUES);
      const errors = await formRef.current.validateForm(formRef.current.values);
      formRef.current.setErrors(errors);
      formRef.current.setTouched(INITIAL_CREATE_FORM_TOUCHED);
    };

    if (formRef?.current && shouldResetForm) {
      setShouldResetForm(false);
      resetForm();
    }
  }, [shouldResetForm]);

  const onCreate = (values: FormValues) =>
    createTranslationFile({
      variables: { input: { ...values, projectId: project?.id || '' } },
    });

  return {
    onCreate,
    isCreating,
  };
};
