import { ApolloError } from '@apollo/client';
import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
} from 'react';
import { ERROR_MESSAGES } from '../../../../libs/apollo/errors';
import HandleErrorService, {
  ErrorHandler,
} from '../../../../libs/HandleErrorService';

const GitAuthStateContext = createContext<{
  gitAuthState: { hasToken: boolean; isTokenValid: boolean };
  handleGitAuthError: (error: ApolloError) => void;
}>({
  gitAuthState: { hasToken: true, isTokenValid: true },
  handleGitAuthError: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const GitAuthStateProvider: FunctionComponent = ({ children }) => {
  const [gitAuthState, setGitAuthState] = useState({
    hasToken: true,
    isTokenValid: true,
  });

  const handleGitAuthError = (
    error: ApolloError,
    errorHandler?: ErrorHandler
  ) => {
    const { message } = error;

    if (message.includes(ERROR_MESSAGES.gitApi.MISSING_GIT_TOKEN)) {
      setGitAuthState({ hasToken: false, isTokenValid: false });
    } else if (message.includes(ERROR_MESSAGES.gitApi.INVALID_GIT_TOKEN)) {
      setGitAuthState({ hasToken: true, isTokenValid: false });
    } else {
      HandleErrorService.showErrorToast(error, errorHandler);
    }
  };

  return (
    <GitAuthStateContext.Provider value={{ gitAuthState, handleGitAuthError }}>
      {children}
    </GitAuthStateContext.Provider>
  );
};

export const useGitAuthStateContext = () => useContext(GitAuthStateContext);
