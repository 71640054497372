import { useEffect } from 'react';

export const useToggleScroll = (isDisabled: boolean) => {
  useEffect(() => {
    if (isDisabled) {
      const topScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      const leftScroll =
        window.pageXOffset || document.documentElement.scrollLeft;

      window.onscroll = () => window.scrollTo(leftScroll, topScroll);
    } else {
      window.onscroll = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
    }
  }, [isDisabled]);
};
