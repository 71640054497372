import React, { createContext, FunctionComponent, useContext } from 'react';

const IsLiveUpdaterContext = createContext<boolean>(false);

type Props = {
  isLiveUpdater: boolean;
};

export const IsLiveUpdaterProvider: FunctionComponent<Props> = ({
  isLiveUpdater,
  children,
}) => (
  <IsLiveUpdaterContext.Provider value={isLiveUpdater}>
    {children}
  </IsLiveUpdaterContext.Provider>
);

export const useIsLiveUpdaterContext = () => useContext(IsLiveUpdaterContext);
