import React, { FunctionComponent, CSSProperties } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { Project } from '../../graphql/queries/organization/type';
import type { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';
import { ROUTES_MAPPING } from '../../../../navigation/Router';

type Props = {
  style?: CSSProperties;
  project?: Project;
};

export const ProjectCard: FunctionComponent<Props> = ({
  project,
  style = {},
}) => {
  const navigate = useNavigate();
  const { organizationSlug } = useParams() as { organizationSlug: string };

  const goToProject = () =>
    navigate(
      ROUTES_MAPPING.project.getPath(organizationSlug, project?.slug || '')
    );

  const goToCreateProject = () =>
    navigate(ROUTES_MAPPING.createProject.getPath(organizationSlug));

  const config = project
    ? { label: project.name, onClick: goToProject }
    : { label: '+', onClick: goToCreateProject };

  return (
    <div
      style={{ ...style, ...styles.container }}
      onClick={config.onClick}
      data-testid="project-card-container"
    >
      <div style={styles.name}>{config.label}</div>
    </div>
  );
};

const styles: Styles = {
  container: {
    ...theme.shadow,
    borderRadius: theme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  name: {
    ...theme.typos.bodyBold,
    color: theme.colors.black,
    textAlign: 'center',
    margin: 3 * theme.margin,
    maxHeight: '100%',
  },
};
