import React, { FunctionComponent, CSSProperties } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import theme from '../../theme';

type Props = {
  size?: number;
  color?: string;
  style?: CSSProperties;
};
export const Loader: FunctionComponent<Props> = ({
  color = theme.colors.black,
  size = 5,
  style = {},
}) => (
  <div style={style} className="loader">
    <BeatLoader size={size} color={color} />
  </div>
);
