import { getAuth, User, onAuthStateChanged } from 'firebase/auth';
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import HandleErrorService from '../../libs/HandleErrorService';

const UserContext = createContext<{
  user: User | null | undefined;
  refreshUser: () => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ user: null, refreshUser: () => {} });

export const UserProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    return onAuthStateChanged(
      getAuth(),
      (newUser) => {
        if (typeof user === 'undefined') {
          setUser(newUser);
        }
      },
      HandleErrorService.showErrorToast
    );
  }, [user]);

  const refreshUser = () => setUser(getAuth().currentUser);

  return (
    <UserContext.Provider value={{ user, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
