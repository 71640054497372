import { useState } from 'react';

export const useModal = () => {
  const [isModalVisible, setIsVisible] = useState(false);

  const showModal = () => setIsVisible(true);

  const hideModal = () => setIsVisible(false);

  return {
    isModalVisible,
    showModal,
    hideModal,
  };
};
