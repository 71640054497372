import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  CSSProperties,
  useEffect,
} from 'react';
import { Input } from '../../../Input/Input';
import type { Styles } from '../../../../types/Styles.type';
import { INITIAL_WIDTHS } from '../Headers/Headers';

type Props = {
  value: string;
  onChange: (value: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  style?: CSSProperties;
};

export const ValueCell: FunctionComponent<Props> = ({
  value: initialValue,
  onChange: onChangeProp,
  style = {},
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);

    return onChangeProp(newValue);
  };

  return (
    <Input
      value={value}
      onChange={onChange}
      multiline
      style={{ ...style, ...styles.container }}
      inputStyle={styles.input}
    />
  );
};

const styles: Styles = {
  container: {
    width: INITIAL_WIDTHS.value,
  },
  input: {
    resize: 'vertical',
  },
};
