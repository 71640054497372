import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useProjectQuery } from '../../hooks/useProjectQuery';

const SelectedBranchContext = createContext<{
  selectedBranch: string | null;
  setSelectedBranch: (branch: string) => void;
}>({
  selectedBranch: null,
  setSelectedBranch: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const SelectedBranchProvider: FunctionComponent = ({ children }) => {
  const { project } = useProjectQuery();
  const [selectedBranch, setSelectedBranch] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedBranch && project?.devBranch) {
      setSelectedBranch(project.devBranch);
    }
  }, [project]);

  return (
    <SelectedBranchContext.Provider
      value={{
        selectedBranch,
        setSelectedBranch,
      }}
    >
      {children}
    </SelectedBranchContext.Provider>
  );
};

export const useSelectedBranchContext = () => useContext(SelectedBranchContext);
