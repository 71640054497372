import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import type { Styles } from '../../../../types/Styles.type';
import { Input, RoundedButton } from '../../../../components';
import theme from '../../../../theme';
import type { FormValues } from './types/FormValues.type';
import { validate } from './formConfig';
import type { NewProject } from '../../stepConfigs';

type Props = {
  newProject: NewProject;
  setNewProject: (project: NewProject) => void;
  onSubmit: (project: NewProject) => void;
  isSubmitting: boolean;
};

export const FillProjectRepoUrl: FunctionComponent<Props> = ({
  newProject,
  setNewProject,
  onSubmit: onSubmitProp,
  isSubmitting,
}) => {
  const intl = useIntl();

  const onSubmit = (values: FormValues) => {
    const updatedNewProject = { ...newProject, repoUrl: values.repoUrl };
    setNewProject(updatedNewProject);
    onSubmitProp(updatedNewProject);
  };

  return (
    <div style={styles.container}>
      <Formik
        initialValues={{ repoUrl: newProject.repoUrl }}
        validate={validate}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => (
          <form style={styles.form}>
            <Input
              style={styles.firstField}
              type="text"
              name="repoUrl"
              autoFocus
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.repoUrl}
              label={intl.formatMessage({
                id: 'createProject.fillRepoUrl.label',
              })}
              error={touched.repoUrl ? errors.repoUrl : undefined}
              canHaveError
            />
            <RoundedButton
              onClick={handleSubmit}
              label={intl.formatMessage({ id: 'createProject.submit' })}
              color={theme.colors.black}
              type="COLORED_BACKGROUND"
              style={styles.button}
              isDisabled={!isValid}
              isLoading={isSubmitting}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: theme.widths.input,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  firstField: {
    width: '100%',
  },
  button: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: 2 * theme.margin,
  },
};
