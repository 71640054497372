import { useState } from 'react';

export const AVAILABLE_PAGE_SIZES = [10, 20, 30, 40, 50, 100];

export const usePagination = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(AVAILABLE_PAGE_SIZES[0]);

  return {
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
  };
};
