import { useApolloClient } from '@apollo/client';
import { FormikTouched } from 'formik';
import { ERROR_MESSAGES } from '../../../../../libs/apollo/errors';
import HandleErrorService, {
  ErrorType,
} from '../../../../../libs/HandleErrorService';
import intl from '../../../../../libs/intl';
import { IS_GIT_PATH_VALID_MUTATION } from '../graphql/mutations/isGitPathValid/mutation';
import {
  IsGitPathValidMutationData,
  IsGitPathValidMutationVariables,
} from '../graphql/mutations/isGitPathValid/types';
import { FormValues, TRANSLATION_FILE_EXTENSIONS } from './type';

export const INITIAL_CREATE_FORM_VALUES: FormValues = {
  gitPath: '',
  countryCode: 'fr',
};

export const INITIAL_CREATE_FORM_TOUCHED: FormikTouched<FormValues> = {
  gitPath: false,
  countryCode: false,
};

export const INITIAL_UPDATE_FORM_TOUCHED: FormikTouched<FormValues> = {
  gitPath: true,
  countryCode: true,
};

export const FORM_ERRORS = {
  gitPath: {
    required: intl.formatMessage({ id: 'form.errors.required' }),
    invalid: intl.formatMessage({
      id: 'project.manageTranslationFilesModal.errors.gitPath.invalid',
    }),
    unsupportedExtension: intl.formatMessage({
      id: 'project.manageTranslationFilesModal.errors.gitPath.unsupportedExtension',
    }),
    notFound: intl.formatMessage({
      id: 'project.manageTranslationFilesModal.errors.gitPath.notFound',
    }),
  },
};

export const useValidate = () => {
  const apolloClient = useApolloClient();

  const getValidate =
    (branch: string) =>
    async ({ gitPath }: FormValues) => {
      const errors: { gitPath?: string } = {};

      const pathAndExt = (gitPath || '').split('.').filter(Boolean);
      const extension = (pathAndExt[pathAndExt.length - 1] || '').toLowerCase();

      if (!gitPath) {
        errors.gitPath = intl.formatMessage({ id: 'form.errors.required' });
      } else if (gitPath.includes(' ') || pathAndExt.length < 2) {
        errors.gitPath = FORM_ERRORS.gitPath.invalid;
      } else if (!TRANSLATION_FILE_EXTENSIONS.includes(extension)) {
        errors.gitPath = FORM_ERRORS.gitPath.unsupportedExtension;
      } else {
        try {
          await apolloClient.mutate<
            IsGitPathValidMutationData,
            IsGitPathValidMutationVariables
          >({
            mutation: IS_GIT_PATH_VALID_MUTATION,
            variables: { input: { gitPath, branch } },
          });
        } catch (error) {
          if (
            error instanceof Error &&
            error.message.includes(ERROR_MESSAGES.gitApi.GIT_FILE_NOT_FOUND)
          ) {
            errors.gitPath = FORM_ERRORS.gitPath.notFound;
          } else {
            HandleErrorService.showErrorToast(error as ErrorType);
          }
        }
      }

      return errors;
    };

  return getValidate;
};
