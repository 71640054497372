import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Formik } from 'formik';
import intl from '../../libs/intl';
import type { Styles } from '../../types/Styles.type';
import { Input, RoundedButton } from '..';
import theme from '../../theme';
import { signIn } from '../../libs/authentication/authentication';
import type { FormValues } from './types/FormValues.type';
import HandleErrorService from '../../libs/HandleErrorService';
import {
  INITIAL_FORM_VALUES,
  VALIDATION_SCHEMA,
  errorHandler,
} from './formConfig';
import { useUserContext } from '../../providers/UserProvider/UserProvider';
import { removeSpaces } from '../Input/helpers/removeSpaces/removeSpaces';

export const SignInForm: FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refreshUser } = useUserContext();

  const onSignInClick = (values: FormValues) => {
    setIsSubmitting(true);
    return signIn(values)
      .then(() => refreshUser())
      .catch((e) => {
        setIsSubmitting(false);
        HandleErrorService.showErrorToast(e, errorHandler);
      })
      .finally(() => setTimeout(() => setIsSubmitting(false), 3000));
  };

  return (
    <Formik
      initialValues={INITIAL_FORM_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSignInClick}
      validateOnMount
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
      }) => (
        <form style={styles.form}>
          <Input
            style={styles.firstField}
            type="email"
            name="email"
            autoFocus
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue('email', removeSpaces(e))
            }
            onBlur={handleBlur}
            value={values.email}
            label={intl.formatMessage({ id: 'form.fields.email' })}
            error={touched.email ? errors.email : undefined}
            canHaveError
          />
          <Input
            style={styles.field}
            type="password"
            name="password"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            label={intl.formatMessage({ id: 'form.fields.password' })}
            error={touched.password ? errors.password : undefined}
            canHaveError
          />
          <RoundedButton
            onClick={handleSubmit}
            label={intl.formatMessage({ id: 'signIn.signIn' })}
            type="WITH_BORDER"
            color={theme.colors.black}
            style={styles.submitButton}
            isLoading={isSubmitting}
            isDisabled={!isValid}
          />
        </form>
      )}
    </Formik>
  );
};

const styles: Styles = {
  form: {
    width: theme.widths.input,
  },
  firstField: {
    width: '100%',
  },
  field: {
    width: '100%',
    marginTop: theme.margin,
  },
  submitButton: {
    marginTop: 4 * theme.margin,
  },
};
