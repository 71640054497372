import React, { FunctionComponent } from 'react';
import sortBy from 'lodash/sortBy';
import { Modal } from '../../../Modal/Modal';
import theme from '../../../../theme';
import type { Styles } from '../../../../types/Styles.type';
import { TranslationFileForm } from '../TranslationFileForm/TranslationFileForm';
import { useProjectQuery } from '../../hooks/useProjectQuery';

type Props = {
  isVisible: boolean;
  hide?: () => void;
};

export const ManageTranslationFilesModal: FunctionComponent<Props> = ({
  isVisible,
  hide,
}) => {
  const { project } = useProjectQuery();

  if (!project) {
    return null;
  }

  const sortedTranslationFiles = sortBy(project.translationFiles, 'id');

  return (
    <Modal isVisible={isVisible} hide={hide}>
      {sortedTranslationFiles.map((translationFile, index) => (
        <TranslationFileForm
          key={translationFile.id}
          translationFile={translationFile}
          style={
            index > 0
              ? styles.translationFileFormWithMargin
              : styles.translationFileForm
          }
        />
      ))}
      {!!sortedTranslationFiles.length && <div style={styles.separator} />}
      <TranslationFileForm style={styles.translationFileForm} />
    </Modal>
  );
};

const SEPARATOR_MARGIN = 4 * theme.margin;

const styles: Styles = {
  translationFileForm: {
    width: '100%',
  },
  translationFileFormWithMargin: {
    width: '100%',
    marginTop: 2 * theme.margin,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.lightGrey,
    marginTop: SEPARATOR_MARGIN,
    marginBottom: SEPARATOR_MARGIN + 2 * theme.margin,
  },
};
