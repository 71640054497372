import React, { CSSProperties, FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { RoundButton } from '../../../RoundButton/RoundButton';
import { useIsLiveUpdaterContext } from '../../../../providers/IsLiveUpdaterProvider/IsLiveUpdaterProvider';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';
import { GET_LIVE_URL_QUERY } from './graphql/queries/liveUrl/query';
import {
  GetLiveUrlQueryData,
  GetLiveUrlQueryVariables,
} from './graphql/queries/liveUrl/types';
import HandleErrorService from '../../../../libs/HandleErrorService';
import { useModal } from '../../../Modal/hooks/useModal';
import { Modal } from '../../../Modal/Modal';
import theme from '../../../../theme';
import { Styles } from '../../../../types/Styles.type';
import { RoundedButton } from '../../../RoundedButton/RoundedButton';
import { Input } from '../../../Input/Input';
import { INITIAL_FORM_VALUES, VALIDATION_SCHEMA } from './form/config';
import {
  UpdateLiveUrlMutationData,
  UpdateLiveUrlMutationVariables,
} from './graphql/mutations/updateLiveUrl/type';
import { UPDATE_LIVE_URL_MUTATION } from './graphql/mutations/updateLiveUrl/mutation';
import { FormValues } from './form/type';

type Props = {
  style?: CSSProperties;
};

const goToLiveUrl = (
  liveUrl: string,
  organizationSlug: string,
  projectSlug: string
) =>
  window.open(
    `${liveUrl}?${LIVE_UPDATER_PROJECT_PATH_PARAM}=${organizationSlug}/${projectSlug}`
  );

export const LIVE_UPDATER_PROJECT_PATH_PARAM = 'louv_project';

export const GoToLiveUpdaterButton: FunctionComponent<Props> = ({
  style = {},
}) => {
  const intl = useIntl();
  const { projectSlug, organizationSlug } = useProjectSlugContext();
  const isLiveUpdater = useIsLiveUpdaterContext();
  const { isModalVisible, showModal, hideModal } = useModal();
  const { data } = useQuery<GetLiveUrlQueryData, GetLiveUrlQueryVariables>(
    GET_LIVE_URL_QUERY,
    {
      variables: {
        slug: projectSlug,
      },
      onError: HandleErrorService.showErrorToast,
    }
  );
  const [updateLiveUrl, { loading: isUpdatingLiveUrl }] = useMutation<
    UpdateLiveUrlMutationData,
    UpdateLiveUrlMutationVariables
  >(UPDATE_LIVE_URL_MUTATION, {
    onError: HandleErrorService.showErrorToast,
    onCompleted: ({ updateProject: { liveUrl } }) => {
      hideModal();
      goToLiveUrl(liveUrl, organizationSlug, projectSlug);
    },
  });

  if (isLiveUpdater || !data) {
    return null;
  }

  const {
    project: { id, liveUrl },
  } = data;

  const onClick = () =>
    liveUrl ? goToLiveUrl(liveUrl, organizationSlug, projectSlug) : showModal();

  const onSubmit = ({ liveUrl: newLiveUrl }: FormValues) =>
    updateLiveUrl({ variables: { input: { id, liveUrl: newLiveUrl } } });

  return (
    <>
      <RoundButton isRound onClick={onClick} label="l" style={style} />
      <Modal isVisible={isModalVisible} hide={hideModal}>
        <Formik
          initialValues={INITIAL_FORM_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => (
            <form style={styles.form}>
              <Input
                style={styles.field}
                type="url"
                name="liveUrl"
                autoFocus
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.liveUrl}
                label={intl.formatMessage({ id: 'form.fields.liveUrl' })}
                error={touched.liveUrl ? errors.liveUrl : undefined}
                canHaveError
              />
              <RoundedButton
                onClick={handleSubmit}
                label={intl.formatMessage({ id: 'save' })}
                type="COLORED_BACKGROUND"
                color={theme.colors.black}
                style={styles.submitButton}
                isLoading={isUpdatingLiveUrl}
                isDisabled={!isValid}
              />
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const styles: Styles = {
  form: {
    width: '100%',
  },
  field: {
    width: '100%',
  },
  submitButton: {
    marginTop: 8 * theme.margin,
    width: '100%',
  },
};
