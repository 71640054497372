import React, { FunctionComponent, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  SignIn,
  Register,
  Landing,
  Documentation,
  Organization,
  Project,
  CreateProject,
  Organizations,
  EditProject,
} from '../pages';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { Toast } from '../components';

export const ROUTES_MAPPING = {
  landing: {
    path: '/',
    Component: Landing,
    requireAuth: false,
  },
  signin: {
    path: '/signin',
    Component: SignIn,
    requireAuth: false,
  },
  register: {
    path: '/register',
    Component: Register,
    requireAuth: false,
  },
  organizations: {
    path: '/organizations',
    Component: Organizations,
    requireAuth: true,
  },
  documentation: {
    path: '/documentation',
    Component: Documentation,
    requireAuth: undefined,
  },
  organization: {
    path: '/:organizationSlug',
    getPath: (organizationSlug: string) => `/${organizationSlug}`,
    Component: Organization,
    requireAuth: true,
  },
  createProject: {
    path: `/:organizationSlug/new`,
    getPath: (organizationSlug: string) => `/${organizationSlug}/new`,
    Component: CreateProject,
    requireAuth: true,
  },
  project: {
    path: `/:organizationSlug/:projectSlug`,
    getPath: (organizationSlug: string, projectSlug: string) =>
      `/${organizationSlug}/${projectSlug}`,
    Component: Project,
    requireAuth: true,
  },
  editProject: {
    path: `/:organizationSlug/:projectSlug/edit`,
    getPath: (organizationSlug: string, projectSlug: string) =>
      `/${organizationSlug}/${projectSlug}/edit`,
    Component: EditProject,
    requireAuth: true,
  },
};

const ROUTES = Object.values(ROUTES_MAPPING);

export const Router: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Routes>
        {ROUTES.map(({ path, Component, requireAuth }) => (
          <Route
            key={path}
            path={path}
            element={
              <PageWrapper requireAuth={requireAuth} isLoading={isLoading}>
                <Component />
              </PageWrapper>
            }
          />
        ))}
      </Routes>
      <Toast />
    </>
  );
};
