import React, { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ProgressBar } from './components/ProgressBar/ProgressBar';
import type { Styles } from '../../types/Styles.type';
import { STEP_CONFIGS, INITIAL_NEW_PROJECT, NewProject } from './stepConfigs';
import theme from '../../theme';
import {
  CreateProjectMutationData,
  CreateProjectMutationVariables,
} from './graphql/mutations/createProject/type';
import { CREATE_PROJECT_MUTATION } from './graphql/mutations/createProject/mutation';
import HandleErrorService from '../../libs/HandleErrorService';
import { ROUTES_MAPPING } from '../../navigation/Router';
import { GET_ORGANIZATION_QUERY } from '../Organization/graphql/queries/organization/query';
import { errorHandler } from './graphql/mutations/createProject/errorHandler';

export const CreateProject: FunctionComponent = () => {
  const [newProject, setNewProject] = useState(INITIAL_NEW_PROJECT);
  const intl = useIntl();
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();
  const { organizationSlug } = useParams() as { organizationSlug: string };

  const goToProject = (projectSlug: string) =>
    navigate(ROUTES_MAPPING.project.getPath(organizationSlug, projectSlug));

  const [createProject, { loading }] = useMutation<
    CreateProjectMutationData,
    CreateProjectMutationVariables
  >(CREATE_PROJECT_MUTATION, {
    refetchQueries: [
      { query: GET_ORGANIZATION_QUERY, variables: { slug: organizationSlug } },
    ],
    onError: (e) => HandleErrorService.showErrorToast(e, errorHandler),
    onCompleted: (data) => {
      goToProject(data.createProject.slug);
      toast.success(intl.formatMessage({ id: 'createProject.success' }));
    },
  });

  const onSceneSubmit = (project: NewProject) => {
    if (stepIndex < STEP_CONFIGS.length - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      const { name, repoUrl } = project;
      createProject({
        variables: { input: { name, repoUrl, organizationSlug } },
      });
    }
  };

  const { Scene } = STEP_CONFIGS[stepIndex];

  return (
    <div style={styles.container}>
      <div style={styles.subcontainer}>
        <ProgressBar
          stepIndex={stepIndex}
          numberOfSteps={STEP_CONFIGS.length}
          setStepIndex={setStepIndex}
          style={styles.progressBar}
        />
        <Scene
          newProject={newProject}
          setNewProject={setNewProject}
          onSubmit={onSceneSubmit}
          isSubmitting={loading}
        />
      </div>
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.widths.subcontainer,
    alignItems: 'center',
    flex: 1,
  },
  progressBar: {
    marginTop: theme.pagePaddings.vertical,
  },
};
