import { FillProjectName } from './components/FillProjectName/FillProjectName';
import { FillProjectRepoUrl } from './components/FillProjectRepoUrl/FillProjectRepoUrl';

export const STEP_CONFIGS = [
  { Scene: FillProjectName },
  { Scene: FillProjectRepoUrl },
];

export const INITIAL_NEW_PROJECT = {
  name: '',
  repoUrl: '',
};

export type NewProject = typeof INITIAL_NEW_PROJECT;
