import React, { FunctionComponent } from 'react';
import theme from '../../theme';
import type { Styles } from '../../types/Styles.type';
import { RoundButton } from '../RoundButton/RoundButton';

type Props = {
  onClick: () => void;
  label: string;
};

export const NoDataLabelAndButton: FunctionComponent<Props> = ({
  onClick,
  label,
}) => (
  <div style={styles.container}>
    <div
      style={styles.subcontainer}
      onClick={onClick}
      data-testid="no-data-content-subcontainer"
    >
      <div style={styles.label}>{label}</div>
      <RoundButton label="+" style={styles.plusButton} isRound />
    </div>
  </div>
);

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    ...theme.typos.body,
    color: theme.colors.black,
    textAlign: 'center',
  },
  plusButton: {
    marginTop: 4 * theme.margin,
  },
};
