import React, { createContext, FunctionComponent, useContext } from 'react';

const ProjectSlugContext = createContext<{
  projectSlug: string;
  organizationSlug: string;
}>({ projectSlug: '', organizationSlug: '' });

type Props = {
  projectSlug: string;
  organizationSlug: string;
};

export const ProjectSlugProvider: FunctionComponent<Props> = ({
  projectSlug,
  organizationSlug,
  children,
}) => (
  <ProjectSlugContext.Provider value={{ projectSlug, organizationSlug }}>
    {children}
  </ProjectSlugContext.Provider>
);

export const useProjectSlugContext = () => useContext(ProjectSlugContext);
