import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { logOut } from '../authentication';
import HandleErrorService from '../../HandleErrorService';
import { useUserContext } from '../../../providers/UserProvider/UserProvider';

export const useLogout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const client = useApolloClient();
  const { refreshUser } = useUserContext();

  const logout = () => {
    setIsLoggingOut(true);
    return logOut()
      .then(() => client.clearStore())
      .then(() => refreshUser())
      .catch((e) => {
        setIsLoggingOut(false);
        HandleErrorService.showErrorToast(e);
      })
      .finally(() => setTimeout(() => setIsLoggingOut(false), 3000));
  };

  return {
    isLoggingOut,
    logout,
  };
};
