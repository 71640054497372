import { useQuery } from '@apollo/client';
import React, { CSSProperties, FunctionComponent } from 'react';
import theme from '../../../../theme';
import { Styles } from '../../../../types/Styles.type';
import { ButtonAndListPopup } from '../../../ButtonAndListPopup/ButtonAndListPopup';
import { useGitAuthStateContext } from '../../providers/GitAuthStateProvider/GitAuthStateProvider';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';
import { useSelectedBranchContext } from '../../providers/SelectedBranchProvider/SelectedBranchProvider';
import { GET_GIT_UNMERGED_GENERATED_BRANCHES_QUERY } from './graphql/queries/gitUnmergedGeneratedBranches/query';
import {
  GetGitUnmergedGeneratedBranchesQueryData,
  GetGitUnmergedGeneratedBranchesQueryVariables,
} from './graphql/queries/gitUnmergedGeneratedBranches/types';

type Props = {
  style?: CSSProperties;
};

const Branch: FunctionComponent<{ datum: string }> = ({ datum: branch }) => (
  <div style={styles.branch}>{branch}</div>
);

export const SelectBranchButton: FunctionComponent<Props> = ({
  style = {},
}) => {
  const { selectedBranch, setSelectedBranch } = useSelectedBranchContext();
  const { handleGitAuthError } = useGitAuthStateContext();
  const { projectSlug } = useProjectSlugContext();

  const { data } = useQuery<
    GetGitUnmergedGeneratedBranchesQueryData,
    GetGitUnmergedGeneratedBranchesQueryVariables
  >(GET_GIT_UNMERGED_GENERATED_BRANCHES_QUERY, {
    variables: {
      slug: projectSlug,
    },
    onError: handleGitAuthError,
  });

  if (!data?.project.devBranch) {
    return null;
  }

  const sortedBranches = [
    data.project.devBranch,
    ...data.project.gitUnmergedGeneratedBranches,
  ].sort();

  const selectedBranchIndex = sortedBranches.findIndex(
    (branch) => branch === selectedBranch
  );

  if (selectedBranchIndex < 0) {
    return null;
  }

  return (
    <ButtonAndListPopup
      style={style}
      data={sortedBranches}
      DatumComponent={Branch}
      onDatumClick={setSelectedBranch}
      selectedDatumIndex={selectedBranchIndex}
      showListBellowButton
      modalWidth={200}
    />
  );
};

const styles: Styles = {
  branch: {
    ...theme.typos.body,
    color: theme.colors.black,
    width: '100%',
  },
};
