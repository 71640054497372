import React, { FunctionComponent, useState } from 'react';
import { KeyValue } from 'louv-converter';
import { Input } from './components/Input/Input';
import { useGetTranslatedNodes } from './hooks/useGetTranslatedNodes';
import { HoverButtons } from './components/HoverButtons/HoverButtons';
import { useToggleScroll } from './hooks/useToggleScroll';
import { useSetSelectedNodeOnMouseEvents } from './hooks/useSetSelectedNodeOnMouseEvents';

type Props = {
  keyValue: KeyValue;
  initialKeyValue: KeyValue;
  onChange: (key: string, value: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const LiveUpdaterTable: FunctionComponent<Props> = ({
  keyValue,
  initialKeyValue,
  onChange,
}) => {
  const [selectedNode, setSelectedNode] = useState<{
    node: Node;
    isEditing?: boolean;
  } | null>(null);
  const translatedNodes = useGetTranslatedNodes(keyValue);
  useToggleScroll(!!selectedNode);
  useSetSelectedNodeOnMouseEvents(
    selectedNode,
    setSelectedNode,
    translatedNodes
  );

  const showInput = () =>
    selectedNode && setSelectedNode({ ...selectedNode, isEditing: true });

  const resetChanges = (key: string) => () => {
    setSelectedNode(null);

    return onChange(key, initialKeyValue[key]);
  };

  const onInputBlur = () => setSelectedNode(null);

  const onInputChange = (key: string) => (value: string) =>
    onChange(key, value);

  if (selectedNode && !selectedNode.isEditing) {
    // @ts-ignore
    const key = selectedNode.node.dataset.louvKey;

    return (
      <HoverButtons
        node={selectedNode.node}
        showInput={showInput}
        resetChanges={resetChanges(key)}
        hasChanges={initialKeyValue[key] !== keyValue[key]}
      />
    );
  }

  if (selectedNode && selectedNode.isEditing) {
    // @ts-ignore
    const key = selectedNode.node.dataset.louvKey;

    return (
      <Input
        node={selectedNode.node}
        onBlur={onInputBlur}
        onChange={onInputChange(key)}
        value={keyValue[key]}
      />
    );
  }

  return null;
};
