import React, { FunctionComponent, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FullScreenLoader } from '../../components';
import HandleErrorService from '../../libs/HandleErrorService';
import { ROUTES_MAPPING } from '../../navigation/Router';
import { GetOrganizationsQueryData } from './graphql/queries/organizations/type';
import { GET_ORGANIZATIONS_QUERY } from './graphql/queries/organizations/query';

export const Organizations: FunctionComponent = () => {
  const navigate = useNavigate();

  const { data, loading } = useQuery<GetOrganizationsQueryData>(
    GET_ORGANIZATIONS_QUERY,
    {
      onError: HandleErrorService.showErrorToast,
    }
  );

  useEffect(() => {
    const organizations = data?.me.organizations;

    if (organizations?.length) {
      const notPersonalOrganization = organizations.find(
        ({ isPersonal }) => !isPersonal
      );
      const organizationToGoTo = notPersonalOrganization || organizations[0];

      navigate(ROUTES_MAPPING.organization.getPath(organizationToGoTo.slug));
    }
  }, [data]);

  if (loading) {
    return <FullScreenLoader />;
  }

  return null;
};
