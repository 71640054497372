export const ERROR_MESSAGES = {
  project: {
    PROJECT_NAME_ALREADY_IN_USE: 'PROJECT_NAME_ALREADY_IN_USE',
    UPDATE_GIT_TOKEN_MISSING_PARAM: 'UPDATE_GIT_TOKEN_MISSING_PARAM',
  },
  translationFile: {
    GIT_PATH_ALREADY_IN_USE: 'GIT_PATH_ALREADY_IN_USE',
  },
  user: {
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  },
  userProject: {
    USER_ALREADY_ADDED_TO_PROJECT: 'USER_ALREADY_ADDED_TO_PROJECT',
  },
  gitApi: {
    INVALID_GIT_TOKEN: 'INVALID_GIT_TOKEN',
    MISSING_GIT_TOKEN: 'MISSING_GIT_TOKEN',
    UNSUPPORTED_GIT_API: 'UNSUPPORTED_GIT_API',
    INVALID_REPO_URL: 'INVALID_REPO_URL',
    GIT_FILE_NOT_FOUND: 'GIT_FILE_NOT_FOUND',
    UNSUPPORTED_GIT_ENCODING: 'UNSUPPORTED_GIT_ENCODING',
    gitHubApi: {
      BAD_CREDENTIALS: 'Bad credentials',
      INVALID_GITHUB_CODE: 'INVALID_GITHUB_CODE',
    },
  },
  keyValueConverter: {
    NO_FILE_EXTENSION: 'NO_FILE_EXTENSION',
    UNSUPPORTED_FILE_EXTENSION: 'UNSUPPORTED_FILE_EXTENSION',
  },
};
