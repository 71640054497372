import React, { FunctionComponent } from 'react';
import type { Styles } from '../../types/Styles.type';
import { Header } from '../Header/Header';
import theme from '../../theme';

export const Page: FunctionComponent = ({ children }) => (
  <div style={styles.container}>
    <Header />
    <div style={styles.childrenContainer}>{children}</div>
  </div>
);

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: theme.pagePaddings.horizontal,
    paddingRight: theme.pagePaddings.horizontal,
    paddingTop: theme.pagePaddings.vertical,
    paddingBottom: theme.pagePaddings.vertical,
  },
};
