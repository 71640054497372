import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useProjectQuery } from '../../hooks/useProjectQuery';

const SelectedTranslationFileIdContext = createContext<{
  selectedTranslationFileId: string | null;
  setSelectedTranslationFileId: (id: string | null) => void;
}>({
  selectedTranslationFileId: null,
  setSelectedTranslationFileId: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const SelectedTranslationFileIdProvider: FunctionComponent = ({
  children,
}) => {
  const { project } = useProjectQuery();
  const [selectedTranslationFileId, setSelectedTranslationFileId] = useState<
    string | null
  >(null);

  useEffect(() => {
    const newSelectedTranslationFile = project?.translationFiles.find(
      ({ id }) => id === selectedTranslationFileId
    );

    if (!newSelectedTranslationFile) {
      setSelectedTranslationFileId(project?.translationFiles[0]?.id || null);
    }
  }, [project]);

  return (
    <SelectedTranslationFileIdContext.Provider
      value={{
        selectedTranslationFileId,
        setSelectedTranslationFileId,
      }}
    >
      {children}
    </SelectedTranslationFileIdContext.Provider>
  );
};

export const useSelectedTranslationFileIdContext = () =>
  useContext(SelectedTranslationFileIdContext);
