import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RawIntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import { Router } from './navigation/Router';
import './libs/firebase';
import intl from './libs/intl';
import { getApolloClient } from './libs/apollo';
import { IsLiveUpdaterProvider } from './providers/IsLiveUpdaterProvider/IsLiveUpdaterProvider';
import { LiveUpdaterRouter } from './navigation/LiveUpdaterRouter';
import { IsUpdatePanelVisibleProvider } from './providers/IsUpdatePanelVisibleProvider/IsUpdatePanelVisibleProvider';
import { UserProvider } from './providers/UserProvider/UserProvider';

type Props = {
  isLiveUpdater?: boolean;
};

export const App: FunctionComponent<Props> = ({ isLiveUpdater = false }) => (
  <UserProvider>
    <IsLiveUpdaterProvider isLiveUpdater={isLiveUpdater}>
      <IsUpdatePanelVisibleProvider>
        <ApolloProvider client={getApolloClient(isLiveUpdater)}>
          <RawIntlProvider value={intl}>
            <BrowserRouter>
              {isLiveUpdater ? <LiveUpdaterRouter /> : <Router />}
            </BrowserRouter>
          </RawIntlProvider>
        </ApolloProvider>
      </IsUpdatePanelVisibleProvider>
    </IsLiveUpdaterProvider>
  </UserProvider>
);
