import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { KeyValueTable, UpdatePanel } from '../../components';
import { Styles } from '../../types/Styles.type';

export const Project: FunctionComponent = () => {
  const { projectSlug, organizationSlug } = useParams() as {
    projectSlug: string;
    organizationSlug: string;
  };

  return (
    <div style={styles.container}>
      <UpdatePanel
        projectSlug={projectSlug}
        organizationSlug={organizationSlug}
        TableComponent={KeyValueTable}
      />
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
};
