import React, { FunctionComponent, CSSProperties } from 'react';
import type { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';
import { INITIAL_WIDTHS } from '../Headers/Headers';

type Props = {
  label: string;
  style?: CSSProperties;
};

export const KeyCell: FunctionComponent<Props> = ({ label, style = {} }) => (
  <div style={{ ...style, ...styles.container }}>{label}</div>
);

const styles: Styles = {
  container: {
    ...theme.typos.body,
    color: theme.colors.black,
    paddingLeft: 2 * theme.margin,
    paddingRight: 2 * theme.margin,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: INITIAL_WIDTHS.key,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
};
