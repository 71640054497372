import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES_MAPPING } from '../../Router';
import { FullScreenLoader, Page } from '../../../components';
import { useUserContext } from '../../../providers/UserProvider/UserProvider';

type Props = {
  requireAuth?: boolean;
  isLoading: boolean;
};

export const PageWrapper: FunctionComponent<Props> = ({
  requireAuth,
  isLoading,
  children,
}) => {
  const { user } = useUserContext();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (requireAuth === undefined) {
    return <Page>{children}</Page>;
  }

  if (typeof user === 'undefined') {
    return null;
  }

  if (requireAuth === true && !user) {
    return <Navigate to={{ pathname: ROUTES_MAPPING.signin.path }} />;
  }

  if (requireAuth === false && user) {
    return <Navigate to={{ pathname: ROUTES_MAPPING.organizations.path }} />;
  }

  return <Page>{children}</Page>;
};
