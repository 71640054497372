import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { FullScreenLoader, Input, RoundedButton } from '../../components';
import HandleErrorService from '../../libs/HandleErrorService';
import theme from '../../theme';
import type { Styles } from '../../types/Styles.type';
import { GET_PROJECT_QUERY } from './graphql/queries/project/query';
import { GetProjectQueryData } from './graphql/queries/project/type';
import { validate } from './formConfig';
import { useModal } from '../../components/Modal/hooks/useModal';
import { DeleteModal } from './components/DeleteModal/DeleteModal';
import { UPDATE_PROJECT_MUTATION } from './graphql/mutations/updateProject/mutation';
import { FormValues } from './types/FormValues.type';
import { ROUTES_MAPPING } from '../../navigation/Router';

export const EditProject: FunctionComponent = () => {
  const { isModalVisible, showModal, hideModal } = useModal();
  const { projectSlug, organizationSlug } = useParams() as {
    projectSlug: string;
    organizationSlug: string;
  };
  const navigate = useNavigate();
  const intl = useIntl();
  const { data, loading } = useQuery<GetProjectQueryData>(GET_PROJECT_QUERY, {
    onError: HandleErrorService.showErrorToast,
    variables: { projectSlug },
  });
  const [updateProject, { loading: isUpdatingProject }] = useMutation(
    UPDATE_PROJECT_MUTATION,
    {
      onError: HandleErrorService.showErrorToast,
      onCompleted: () => {
        navigate(ROUTES_MAPPING.project.getPath(organizationSlug, projectSlug));
        toast.success(intl.formatMessage({ id: 'editProject.updateSuccess' }));
      },
    }
  );

  if (loading && !data) {
    return <FullScreenLoader testId="loader" />;
  }

  if (!data) {
    return null;
  }

  const onSubmit = (values: FormValues) =>
    updateProject({ variables: { id: data.project.id, ...values } });

  const { name, repoUrl, devBranch } = data.project;

  return (
    <div style={styles.container}>
      <div style={styles.subcontainer}>
        <Formik
          initialValues={{ devBranch }}
          validate={validate}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            initialValues,
          }) => (
            <form style={styles.form}>
              <Input
                style={styles.firstField}
                type="text"
                required
                value={name}
                label={intl.formatMessage({
                  id: 'createProject.fillName.label',
                })}
                disabled
                canHaveError
              />
              <Input
                style={styles.field}
                type="text"
                required
                value={repoUrl}
                label={intl.formatMessage({
                  id: 'createProject.fillRepoUrl.label',
                })}
                disabled
                canHaveError
              />
              <Input
                style={styles.field}
                type="text"
                name="devBranch"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.devBranch}
                label={intl.formatMessage({
                  id: 'createProject.fillDevBranch.label',
                })}
                error={touched.devBranch ? errors.devBranch : undefined}
                canHaveError
              />
              <RoundedButton
                onClick={handleSubmit}
                label={intl.formatMessage({ id: 'save' })}
                type="COLORED_BACKGROUND"
                color={theme.colors.black}
                style={styles.submitButton}
                isLoading={isUpdatingProject}
                isDisabled={!isValid || isEqual(initialValues, values)}
              />
              <RoundedButton
                onClick={showModal}
                label={intl.formatMessage({ id: 'delete' })}
                type="WITH_BORDER"
                color={theme.colors.black}
                style={styles.deleteButton}
              />
            </form>
          )}
        </Formik>
      </div>
      <DeleteModal isVisible={isModalVisible} hide={hideModal} />
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.widths.input,
  },
  form: {
    width: '100%',
  },
  firstField: {
    width: '100%',
  },
  field: {
    width: '100%',
    marginTop: theme.margin,
  },
  submitButton: {
    marginTop: 4 * theme.margin,
  },
  deleteButton: {
    marginTop: 2 * theme.margin,
  },
};
