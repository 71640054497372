import React, { CSSProperties, FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import theme from '../../../../theme';
import type { Styles } from '../../../../types/Styles.type';
import { CreatePRButton } from '../CreatePRButton/CreatePRButton';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';
import {
  GetTranslationFilesKeyValuesQueryData,
  GetTranslationFilesKeyValuesQueryVariables,
} from './graphql/queries/translationFilesKeyValues/types';
import { GET_TRANSLATION_FILES_KEY_VALUES_QUERY } from './graphql/queries/translationFilesKeyValues/query';
import { useSelectedBranchContext } from '../../providers/SelectedBranchProvider/SelectedBranchProvider';
import { useGitAuthStateContext } from '../../providers/GitAuthStateProvider/GitAuthStateProvider';
import { ResetChangesButton } from '../ResetChangesButton/ResetChangesButton';
import { GoToLiveUpdaterButton } from '../GoToLiveUpdaterButton/GoToLiveUpdaterButton';

type Props = {
  style?: CSSProperties;
};

export const Footer: FunctionComponent<Props> = ({ style = {} }) => {
  const { selectedBranch } = useSelectedBranchContext();
  const { projectSlug } = useProjectSlugContext();
  const { handleGitAuthError } = useGitAuthStateContext();

  const { data } = useQuery<
    GetTranslationFilesKeyValuesQueryData,
    GetTranslationFilesKeyValuesQueryVariables
  >(GET_TRANSLATION_FILES_KEY_VALUES_QUERY, {
    variables: {
      slug: projectSlug,
      branch: selectedBranch || undefined,
    },
    onError: handleGitAuthError,
  });

  if (!data) {
    return null;
  }

  const {
    project: { translationFiles },
  } = data;

  return (
    <div style={{ ...style, ...styles.container }}>
      <ResetChangesButton translationFiles={translationFiles} />
      <CreatePRButton
        translationFiles={translationFiles}
        style={styles.button}
      />
      <GoToLiveUpdaterButton style={styles.button} />
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginLeft: 2 * theme.margin,
  },
};
