import { useMutation } from '@apollo/client';
import HandleErrorService from '../../../../../libs/HandleErrorService';
import { UPDATE_TRANSLATION_FILE_MUTATION } from '../graphql/mutations/updateTranslationFile/mutation';
import type {
  UpdateTranslationFileMutationData,
  UpdateTranslationFileMutationVariables,
} from '../graphql/mutations/updateTranslationFile/types';
import { FormValues } from '../formConfig/type';
import { errorHandler } from '../graphql/mutations/createTranslationFile/errorHandler';
import { useDebouncedMutate } from '../../../../../libs/apollo/hooks/useDebouncedMutate';

export const useUpdateTranslationFile = () => {
  const [updateTranslationFile, { loading: isUpdating }] = useMutation<
    UpdateTranslationFileMutationData,
    UpdateTranslationFileMutationVariables
  >(UPDATE_TRANSLATION_FILE_MUTATION, {
    onError: (e) => HandleErrorService.showErrorToast(e, errorHandler),
  });

  const debouncedUpdateTranslationFile = useDebouncedMutate(
    updateTranslationFile
  );

  const onUpdate = ({ id, gitPath, countryCode }: FormValues) =>
    debouncedUpdateTranslationFile({
      variables: { input: { id: id as string, gitPath, countryCode } },
    });

  return {
    onUpdate,
    isUpdating,
  };
};
