import { gql } from '@apollo/client';

export const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject($id: ID!, $devBranch: String!) {
    updateProject(input: { id: $id, devBranch: $devBranch }) {
      id
      slug
      name
      repoUrl
      devBranch
      translationFiles {
        id
        countryCode
        gitPath
        keyValue
      }
    }
  }
`;
