import { useMutation } from '@apollo/client';
import HandleErrorService from '../../../../../libs/HandleErrorService';
import {
  DELETE_TRANSLATION_FILE_MUTATION,
  getDeleteTranslationFileMutationRefetchQueries,
} from '../graphql/mutations/deleteTranslationFile/mutation';
import { FormValues } from '../formConfig/type';
import type {
  DeleteTranslationFileMutationData,
  DeleteTranslationFileMutationVariables,
} from '../graphql/mutations/deleteTranslationFile/types';
import { useProjectQuery } from '../../../hooks/useProjectQuery';

export const useDeleteTranslationFile = () => {
  const { project } = useProjectQuery();

  const [deleteTranslationFile, { loading: isDeleting }] = useMutation<
    DeleteTranslationFileMutationData,
    DeleteTranslationFileMutationVariables
  >(DELETE_TRANSLATION_FILE_MUTATION, {
    refetchQueries: getDeleteTranslationFileMutationRefetchQueries(
      project?.slug || ''
    ),
    onError: HandleErrorService.showErrorToast,
    awaitRefetchQueries: true,
  });

  const onDelete = (values: FormValues) =>
    deleteTranslationFile({
      variables: { id: values.id as string },
    });

  return {
    onDelete,
    isDeleting,
  };
};
