import { useIntl } from 'react-intl';
import { MutableRefObject } from 'react';
import { FormikProps } from 'formik';
import {
  INITIAL_CREATE_FORM_TOUCHED,
  INITIAL_CREATE_FORM_VALUES,
  INITIAL_UPDATE_FORM_TOUCHED,
} from '../formConfig/formConfig';
import { FormValues } from '../formConfig/type';
import { useCreateTranslationFile } from './useCreateTranslationFile';
import { useDeleteTranslationFile } from './useDeleteTranslationFile';
import { TranslationFile } from '../../../graphql/queries/project/types';

export const useTranslationFileFormConfig = ({
  formRef,
  translationFile,
}: {
  formRef: MutableRefObject<null | FormikProps<FormValues>>;
  translationFile?: TranslationFile;
}) => {
  const intl = useIntl();
  const { isDeleting, onDelete } = useDeleteTranslationFile();
  const { isCreating, onCreate } = useCreateTranslationFile(formRef);

  if (!translationFile) {
    return {
      initialValues: INITIAL_CREATE_FORM_VALUES,
      initialTouched: INITIAL_CREATE_FORM_TOUCHED,
      createOrDeleteButton: {
        getOnClick: (values: FormValues) => () => onCreate(values),
        label: '+',
        isLoading: isCreating,
        getIsDisabled: (isValid: boolean) => !isValid,
      },
      placeholder: intl.formatMessage({
        id: 'project.manageTranslationFilesModal.newGitPathPlaceholder',
      }),
    };
  }

  return {
    initialValues: translationFile,
    initialTouched: INITIAL_UPDATE_FORM_TOUCHED,
    createOrDeleteButton: {
      getOnClick: (values: FormValues) => () => onDelete(values),
      label: '-',
      isLoading: isDeleting,
      getIsDisabled: () => false,
    },
    placeholder: intl.formatMessage({
      id: 'project.manageTranslationFilesModal.gitPathPlaceholder',
    }),
  };
};
