import React, { CSSProperties, FunctionComponent } from 'react';
import theme from '../../../../theme';
import { Styles } from '../../../../types/Styles.type';

type Props = {
  countryCode: string;
  showCodeLabel?: boolean;
  style?: CSSProperties;
};

export const CountryCodeFlag: FunctionComponent<Props> = ({
  countryCode,
  showCodeLabel,
  style = {},
}) => (
  <div style={{ ...style, ...styles.container }}>
    {showCodeLabel && (
      <div style={styles.code}>{countryCode.toUpperCase()}</div>
    )}
    <img
      style={styles.flag}
      alt={countryCode}
      src={`${process.env.REACT_APP_FRONT_URL}/images/countryFlags/32x32/${countryCode}.png`}
    />
  </div>
);

const FLAG_WIDTH = 20;

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  code: {
    ...theme.typos.body,
    color: theme.colors.black,
  },
  flag: {
    height: FLAG_WIDTH,
    width: FLAG_WIDTH,
  },
};
