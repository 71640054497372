import { createIntl, createIntlCache } from 'react-intl';
import { convertJsonObjectToKeyValue } from 'louv-converter';
import {
  DEFAULT_TRANSLATIONS_KEY,
  TRANSLATIONS_KEYS,
  translations,
} from './translations';
import type { TranslationsKey } from './translations';

const getLocale = (): TranslationsKey => {
  const { languages } = window.navigator;

  const language = languages?.length ? languages[0] : window.navigator.language;

  if (!language) {
    return DEFAULT_TRANSLATIONS_KEY;
  }

  const [translationsKey] = language.trim().split(/-|_/);

  return (
    TRANSLATIONS_KEYS.find((key) => key === translationsKey) ||
    DEFAULT_TRANSLATIONS_KEY
  );
};

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: getLocale(),
    messages: convertJsonObjectToKeyValue(translations[getLocale()]),
  },
  cache
);

export default intl;
