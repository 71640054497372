import { gql } from '@apollo/client';

export const GET_LIVE_URL_QUERY = gql`
  query LiveUrl($slug: String!) {
    project(slug: $slug) {
      id
      liveUrl
    }
  }
`;
