export const getNodePositionStyle = (node: Node) => {
  // @ts-ignore
  if (!node.getBoundingClientRect) {
    return undefined;
  }

  // @ts-ignore
  const { width, height, top, left } = node.getBoundingClientRect();

  return {
    width,
    height,
    top,
    left,
  };
};
