import { gql } from '@apollo/client';

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      slug
    }
  }
`;
