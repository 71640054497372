import { useEffect, useState } from 'react';
import { PREPARED_KEY_VALUE_SEPARATOR, KeyValue } from 'louv-converter';

export const useGetTranslatedNodes = (keyValue: KeyValue) => {
  const [translatedNodes, setTranslatedNodes] = useState<Node[]>([]);

  const getTranslatedNodes = () => {
    const newTranslatedNodes = [];

    const results = document.evaluate(
      `//*[contains(text(),'${PREPARED_KEY_VALUE_SEPARATOR}')]`,
      document,
      null,
      XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null
    );

    let index = 0;
    let node = results.snapshotItem(index);
    while (node) {
      // @ts-ignore
      if (node.innerText) {
        // @ts-ignore
        const [key] = node.innerText.split(PREPARED_KEY_VALUE_SEPARATOR);
        // @ts-ignore
        node.dataset.louvKey = key;

        newTranslatedNodes.push(node);
      }

      index += 1;
      node = results.snapshotItem(index);
    }

    const alreadyTranslatedNodes = Array.from(
      document.querySelectorAll('[data-louv-key]')
    );

    setTranslatedNodes([...newTranslatedNodes, ...alreadyTranslatedNodes]);
  };

  useEffect(() => {
    translatedNodes.forEach((node) => {
      // @ts-ignore
      const key = node.dataset.louvKey;
      // @ts-ignore
      node.innerText = keyValue[key]; // eslint-disable-line no-param-reassign
    });
  }, [translatedNodes, keyValue]);

  useEffect(() => {
    const interval = setInterval(getTranslatedNodes, 1000);

    return () => clearInterval(interval);
  }, []);

  return translatedNodes;
};
