import { useState } from 'react';

export const useCheckbox = (initialIsChecked = false) => {
  const [isChecked, setIsChecked] = useState(initialIsChecked);

  const toggleIsChecked = () => setIsChecked(!isChecked);

  return {
    isChecked,
    toggleIsChecked,
  };
};
