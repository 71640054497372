import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { Modal } from '../../../Modal/Modal';
import { ButtonAndListPopup } from '../../../ButtonAndListPopup/ButtonAndListPopup';
import { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';
import { RoundedButton } from '../../../RoundedButton/RoundedButton';
import { UPDATE_DEV_BRANCH_MUTATION } from './graphql/mutations/updateProject/mutation';
import {
  UpdateDevBranchMutationData,
  UpdateDevBranchMutationVariables,
} from './graphql/mutations/updateProject/type';
import HandleErrorService from '../../../../libs/HandleErrorService';
import { GET_GIT_BRANCHES_QUERY } from './graphql/queries/gitBranches/query';
import {
  GetGitBranchesQueryData,
  GetGitBranchesQueryVariables,
} from './graphql/queries/gitBranches/types';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';
import { useGitAuthStateContext } from '../../providers/GitAuthStateProvider/GitAuthStateProvider';
import { FullScreenLoader } from '../../../FullScreenLoader/FullScreenLoader';

const Branch: FunctionComponent<{ datum: string }> = ({ datum: branch }) => (
  <div style={styles.branch}>{branch}</div>
);

type Props = {
  isVisible: boolean;
  hide?: () => void;
};

export const SelectDevBranchModal: FunctionComponent<Props> = ({
  isVisible,
  hide,
}) => {
  const intl = useIntl();
  const { projectSlug } = useProjectSlugContext();
  const { handleGitAuthError } = useGitAuthStateContext();
  const [selectedBranch, setSelectedBranch] = useState<string | null>(null);

  const { data, loading: isFetchingGitBranches } = useQuery<
    GetGitBranchesQueryData,
    GetGitBranchesQueryVariables
  >(GET_GIT_BRANCHES_QUERY, {
    variables: {
      slug: projectSlug,
    },
    onError: handleGitAuthError,
    onCompleted: ({ project: { gitBranches } }) =>
      setSelectedBranch(gitBranches[0] || null),
  });

  const [updateDevBranch, { loading: isUpdatingDevBranch }] = useMutation<
    UpdateDevBranchMutationData,
    UpdateDevBranchMutationVariables
  >(UPDATE_DEV_BRANCH_MUTATION, {
    onError: HandleErrorService.showErrorToast,
  });

  if (!data && isFetchingGitBranches) {
    return <FullScreenLoader />;
  }

  if (!data || !selectedBranch) {
    return null;
  }

  const onSubmit = () =>
    updateDevBranch({
      variables: { input: { id: data.project.id, devBranch: selectedBranch } },
    });

  const sortedBranches = [...data.project.gitBranches].sort();
  const selectedBranchIndex = sortedBranches.findIndex(
    (branch) => branch === selectedBranch
  );

  return (
    <Modal isVisible={isVisible} hide={hide}>
      <ButtonAndListPopup
        data={sortedBranches}
        DatumComponent={Branch}
        onDatumClick={setSelectedBranch}
        selectedDatumIndex={selectedBranchIndex}
        modalWidth={300}
        style={styles.buttonAndListPopup}
      />
      <RoundedButton
        onClick={onSubmit}
        label={intl.formatMessage({ id: 'confirm' })}
        type="COLORED_BACKGROUND"
        color={theme.colors.black}
        style={styles.confirmButton}
        isLoading={isUpdatingDevBranch}
      />
    </Modal>
  );
};

const styles: Styles = {
  branch: {
    ...theme.typos.body,
    color: theme.colors.black,
    width: '100%',
  },
  buttonAndListPopup: {
    width: '100%',
  },
  confirmButton: {
    marginTop: 8 * theme.margin,
    width: '100%',
  },
};
