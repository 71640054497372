import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useDebouncedMutate } from '../../../libs/apollo/hooks/useDebouncedMutate';
import HandleErrorService from '../../../libs/HandleErrorService';
import { UPDATE_KEY_VALUE_CHANGES_MUTATION } from '../graphql/mutations/updateKeyValueChanges/mutation';
import {
  UpdateKeyValueChangesMutationData,
  UpdateKeyValueChangesMutationVariables,
} from '../graphql/mutations/updateKeyValueChanges/types';
import { TranslationFile } from '../components/TableComponentWrapper/graphql/queries/translationFile/types';
import { useSelectedTranslationFileIdContext } from '../providers/SelectedTranslationFileIdProvider/SelectedTranslationFileIdProvider';
import { useIsUpdatingKeyValueChangesContext } from '../providers/IsUpdatingKeyValueChangesProvider.tsx/IsUpdatingKeyValueChangesProvider';

export const useUpdateKeyValueChanges = () => {
  const { selectedTranslationFileId } = useSelectedTranslationFileIdContext();
  const { setIsUpdatingKeyValueChanges } =
    useIsUpdatingKeyValueChangesContext();

  const [mutate, { loading }] = useMutation<
    UpdateKeyValueChangesMutationData,
    UpdateKeyValueChangesMutationVariables
  >(UPDATE_KEY_VALUE_CHANGES_MUTATION, {
    onError: HandleErrorService.showErrorToast,
  });

  useEffect(() => {
    setIsUpdatingKeyValueChanges(loading);
  }, [loading]);

  const debouncedOnUpdateKeyValueChanges = useDebouncedMutate(mutate);

  const updateKeyValueChanges =
    (translationFile: TranslationFile) =>
    (key: string, value: string): Promise<any> => {
      const { id, keyValueChanges: keyValueChangesString } = translationFile;

      const keyValueChanges = keyValueChangesString
        ? JSON.parse(keyValueChangesString)
        : {};

      return debouncedOnUpdateKeyValueChanges({
        variables: {
          input: {
            id,
            keyValueChanges: JSON.stringify({
              ...keyValueChanges,
              [key]: value,
            }),
          },
        },
      }) as Promise<any>;
    };

  const resetKeyValueChanges = (): Promise<any> => {
    if (!selectedTranslationFileId) {
      return Promise.resolve(null);
    }

    return mutate({
      variables: {
        input: {
          id: selectedTranslationFileId,
          keyValueChanges: JSON.stringify({}),
        },
      },
    });
  };

  return {
    updateKeyValueChanges,
    resetKeyValueChanges,
  };
};
