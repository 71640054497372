export const getHasTranslationFileChanges = ({
  keyValue: keyValueString,
  keyValueChanges: keyValueChangesString,
}: {
  keyValue: string;
  keyValueChanges?: string;
}): boolean => {
  const keyValue = JSON.parse(keyValueString);
  const keyValueChanges = keyValueChangesString
    ? JSON.parse(keyValueChangesString)
    : null;

  return (
    keyValueChanges &&
    Object.keys(keyValueChanges).some(
      (key) =>
        keyValue[key] !== undefined &&
        keyValueChanges[key] !== undefined &&
        keyValue[key] !== keyValueChanges[key]
    )
  );
};
