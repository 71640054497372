import { object, string } from 'yup';
import intl from '../../../../../libs/intl';
import { FormValues } from './type';

export const INITIAL_FORM_VALUES: FormValues = {
  liveUrl: '',
};

export const VALIDATION_SCHEMA = object().shape({
  liveUrl:
    process.env.REACT_APP_ENV === 'dev'
      ? string().required(intl.formatMessage({ id: 'form.errors.required' }))
      : string()
          .url(intl.formatMessage({ id: 'form.errors.invalidUrl' }))
          .required(intl.formatMessage({ id: 'form.errors.required' })),
});
