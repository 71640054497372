import React, { CSSProperties, FunctionComponent } from 'react';
import theme from '../../../../theme';
import type { Styles } from '../../../../types/Styles.type';
import { useIsUpdatePanelVisibleContext } from '../../../../providers/IsUpdatePanelVisibleProvider/IsUpdatePanelVisibleProvider';
import { RoundButton } from '../../../RoundButton/RoundButton';

type Props = {
  style?: CSSProperties;
};

export const ToggleIsUpdatePanelVisibleButton: FunctionComponent<Props> = ({
  style = {},
}) => {
  const { isUpdatePanelVisible, setIsUpdatePanelVisible } =
    useIsUpdatePanelVisibleContext();

  const toggleIsUpdatePanelVisible = () =>
    setIsUpdatePanelVisible(!isUpdatePanelVisible);

  return (
    <RoundButton
      onClick={toggleIsUpdatePanelVisible}
      label={isUpdatePanelVisible ? '>' : '<'}
      testId="toggle-is-update-panel-visible-button"
      style={{
        ...style,
        ...styles.container,
      }}
      contentContainerStyle={styles.contentContainer}
    />
  );
};

const styles: Styles = {
  container: {
    width: 50,
    marginRight: -15,
  },
  contentContainer: {
    marginRight: theme.margin,
  },
};
