import React, { FunctionComponent } from 'react';
import type { CSSProperties } from 'react';
import theme from '../../theme';
import type { Styles } from '../../types/Styles.type';
import { Loader } from '../Loader/Loader';

export type Props = {
  onClick?: () => void;
  label?: string;
  style?: CSSProperties;
  testId?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isRound?: boolean;
  contentContainerStyle?: CSSProperties;
};

export const ROUND_BUTTON_HEIGHT = 40;

export const RoundButton: FunctionComponent<Props> = ({
  onClick,
  label,
  children,
  style = {},
  testId,
  isLoading,
  isDisabled,
  isRound,
  contentContainerStyle = {},
}) => {
  const renderContent = () =>
    label ? <div style={styles.label}>{label}</div> : children;

  let additionalContainerStyle: CSSProperties = {
    paddingLeft: 2 * theme.margin,
    paddingRight: 2 * theme.margin,
  };
  if (isRound) {
    additionalContainerStyle = {
      width: ROUND_BUTTON_HEIGHT,
    };
  }

  return (
    <div
      style={{
        ...style,
        ...styles.container,
        ...additionalContainerStyle,
      }}
      onClick={isDisabled || isLoading ? undefined : onClick}
      data-testid={testId}
    >
      {isLoading ? (
        <Loader color={theme.colors.black} size={3} />
      ) : (
        <div
          style={{
            ...contentContainerStyle,
            opacity: isDisabled ? theme.disabledOpacity : 1,
          }}
        >
          {renderContent()}
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  container: {
    ...theme.shadow,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: ROUND_BUTTON_HEIGHT,
    borderRadius: ROUND_BUTTON_HEIGHT / 2,
    backgroundColor: theme.colors.white,
    pointerEvents: 'auto',
    boxSizing: 'border-box',
  },
  label: {
    ...theme.typos.bodyBold,
    color: theme.colors.black,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
};
