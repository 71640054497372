import intl from '../../../../libs/intl';
import type { FormValues } from './types/FormValues.type';

const GITHUB_BASE_URL = 'https://github.com/';

const isGitRepoUrlValid = (url: string) => {
  if (!url.startsWith(GITHUB_BASE_URL)) {
    return false;
  }

  const ownerAndRepo = url
    .replace(GITHUB_BASE_URL, '')
    .split('/')
    .filter(Boolean);

  return ownerAndRepo.length === 2;
};

export const validate = (values: FormValues) => {
  const errors = {};

  if (!values.repoUrl) {
    // @ts-ignore
    errors.repoUrl = intl.formatMessage({ id: 'form.errors.required' });
  } else if (!isGitRepoUrlValid(values.repoUrl)) {
    // @ts-ignore
    errors.repoUrl = intl.formatMessage({
      id: 'createProject.fillRepoUrl.requiredGithubFormat',
    });
  }

  return errors;
};
