import React, { CSSProperties, FunctionComponent } from 'react';
import { RoundButton } from '../../../RoundButton/RoundButton';
import { useSelectedTranslationFileIdContext } from '../../providers/SelectedTranslationFileIdProvider/SelectedTranslationFileIdProvider';
import { useUpdateKeyValueChanges } from '../../hooks/useUpdateKeyValueChanges';
import { useIsUpdatingKeyValueChangesContext } from '../../providers/IsUpdatingKeyValueChangesProvider.tsx/IsUpdatingKeyValueChangesProvider';
import { TranslationFile } from '../Footer/graphql/queries/translationFilesKeyValues/types';
import { getHasTranslationFileChanges } from '../../helpers/getHasTranslationFileChanges/getHasTranslationFileChanges.helper';

type Props = {
  translationFiles: TranslationFile[];
  style?: CSSProperties;
};

export const ResetChangesButton: FunctionComponent<Props> = ({
  translationFiles,
  style = {},
}) => {
  const { selectedTranslationFileId } = useSelectedTranslationFileIdContext();
  const { resetKeyValueChanges } = useUpdateKeyValueChanges();
  const { isUpdatingKeyValueChanges } = useIsUpdatingKeyValueChangesContext();

  const translationFile = translationFiles.find(
    ({ id }) => id === selectedTranslationFileId
  );
  const hasChanges =
    translationFile && getHasTranslationFileChanges(translationFile);

  if (!hasChanges && !isUpdatingKeyValueChanges) {
    return null;
  }

  return (
    <RoundButton
      isRound
      onClick={resetKeyValueChanges}
      label="r"
      isLoading={isUpdatingKeyValueChanges}
      testId="reset-button"
      style={style}
    />
  );
};
