import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Modal,
  SignInForm,
  UpdatePanel,
  LiveUpdaterTable,
  FullScreenLoader,
  Toast,
} from '../components';
import { Styles } from '../types/Styles.type';
import theme from '../theme';
import { LiveUpdaterLeaveButtons } from './components/LiveUpdaterLeaveButtons/LiveUpdaterLeaveButtons';
import { useGetLiveUpdaterProjectSlug } from './hooks/useGetLiveUpdaterProjectSlug';
import { useIsUpdatePanelVisibleContext } from '../providers/IsUpdatePanelVisibleProvider/IsUpdatePanelVisibleProvider';
import { useUserContext } from '../providers/UserProvider/UserProvider';

export const LiveUpdaterRouter: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { projectSlug, organizationSlug } = useGetLiveUpdaterProjectSlug();
  const { isUpdatePanelVisible } = useIsUpdatePanelVisibleContext();
  const { user } = useUserContext();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  if (!projectSlug || !organizationSlug || typeof user === 'undefined') {
    return null;
  }

  const renderContent = () => {
    if (!user) {
      return (
        <Modal isVisible>
          <SignInForm />
        </Modal>
      );
    }

    return (
      <UpdatePanel
        TableComponent={LiveUpdaterTable}
        projectSlug={projectSlug}
        organizationSlug={organizationSlug}
      />
    );
  };

  return (
    <>
      <div style={styles.container}>{renderContent()}</div>
      {isLoading && (
        <div style={styles.loaderContainer}>
          <FullScreenLoader />
        </div>
      )}
      {isUpdatePanelVisible && (
        <LiveUpdaterLeaveButtons style={styles.leaveButtons} />
      )}
      <Toast />
    </>
  );
};

const styles: Styles = {
  container: {
    ...theme.liveUpdaterFixedStyle,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: theme.pagePaddings.liveUpdater.vertical,
    pointerEvents: 'none',
  },
  loaderContainer: {
    ...theme.liveUpdaterFixedStyle,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.colors.white,
  },
  leaveButtons: {
    ...theme.liveUpdaterFixedStyle,
    left: theme.pagePaddings.liveUpdater.horizontal,
    bottom: theme.pagePaddings.liveUpdater.vertical,
  },
};
