import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

export const signIn = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => signInWithEmailAndPassword(getAuth(), email, password);

export const register = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => createUserWithEmailAndPassword(getAuth(), email, password);

export const logOut = () => signOut(getAuth());
