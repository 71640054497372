import React, { CSSProperties, FunctionComponent } from 'react';
import { PROJECT_PATH_ITEM_KEY } from 'louv-converter';
import { useQuery } from '@apollo/client';
import { RoundButton } from '../../../components';
import { Styles } from '../../../types/Styles.type';
import theme from '../../../theme';
import { GET_PROJECT_QUERY } from '../../../components/UpdatePanel/graphql/queries/project/query';
import {
  GetProjectQueryData,
  GetProjectQueryVariables,
} from '../../graphql/queries/project/types';
import { useLogout } from '../../../libs/authentication/hooks/useLogout';
import { useUserContext } from '../../../providers/UserProvider/UserProvider';

type Props = {
  style?: CSSProperties;
};

export const LiveUpdaterLeaveButtons: FunctionComponent<Props> = ({
  style,
}) => {
  const { user } = useUserContext();
  const { isLoggingOut, logout } = useLogout();
  const projectPath = localStorage.getItem(PROJECT_PATH_ITEM_KEY);
  const [projectSlug] = (projectPath || '').split('/').filter(Boolean);

  useQuery<GetProjectQueryData, GetProjectQueryVariables>(GET_PROJECT_QUERY, {
    variables: { slug: projectSlug as string },
    skip: !projectSlug || !user,
    onError: logout,
  });

  const leaveLiveUpdater = () => {
    localStorage.removeItem(PROJECT_PATH_ITEM_KEY);
    window.location.href = `${window.location.href}`;
  };

  return (
    <div style={{ ...style, ...styles.container }}>
      <RoundButton isRound label="l" onClick={leaveLiveUpdater} />
      {!!user && (
        <RoundButton
          isRound
          label="o"
          onClick={logout}
          style={styles.logoutButton}
          isLoading={isLoggingOut}
        />
      )}
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoutButton: {
    marginLeft: 2 * theme.margin,
  },
};
