import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import type { Styles } from '../../types/Styles.type';
import { ROUTES_MAPPING } from '../../navigation/Router';
import theme from '../../theme';
import { ProjectCard } from './components/ProjectCard/ProjectCard';
import HandleErrorService from '../../libs/HandleErrorService';
import type {
  GetOrganizationQueryData,
  GetOrganizationQueryVariables,
} from './graphql/queries/organization/type';
import { GET_ORGANIZATION_QUERY } from './graphql/queries/organization/query';
import { FullScreenLoader, NoDataLabelAndButton } from '../../components';

export const Organization: FunctionComponent = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { organizationSlug } = useParams() as { organizationSlug: string };

  const { data, loading } = useQuery<
    GetOrganizationQueryData,
    GetOrganizationQueryVariables
  >(GET_ORGANIZATION_QUERY, {
    onError: HandleErrorService.showErrorToast,
    variables: { slug: organizationSlug },
  });

  const onCreateProjectClick = () =>
    navigate(ROUTES_MAPPING.createProject.getPath(organizationSlug));

  if (loading && !data) {
    return <FullScreenLoader testId="loader" />;
  }

  if (!data) {
    return null;
  }

  const { projects } = data.organization;

  if (!projects.length) {
    return (
      <NoDataLabelAndButton
        onClick={onCreateProjectClick}
        label={intl.formatMessage({ id: 'projects.noProject' })}
      />
    );
  }

  let subcontainerStyle = styles.subcontainer;
  if (projects.length < NUMBER_OF_CARDS_PER_LINE) {
    subcontainerStyle = { ...subcontainerStyle, ...styles.smallSubcontainer };
  }

  return (
    <div style={styles.container}>
      <div style={subcontainerStyle}>
        {projects.map((project) => (
          <ProjectCard
            project={project}
            key={project.id}
            style={styles.projectCard}
          />
        ))}
        <ProjectCard style={styles.projectCard} />
      </div>
    </div>
  );
};

const NUMBER_OF_CARDS_PER_LINE = 3;
const SUBCONTAINER_WIDTH = theme.widths.subcontainer;
const PROJECT_CARD_HEIGHT = 200;
const PROJECT_CARD_MARGIN = 2 * theme.margin;
const PROJECT_CARD_WIDTH =
  (SUBCONTAINER_WIDTH - NUMBER_OF_CARDS_PER_LINE * 2 * PROJECT_CARD_MARGIN) /
  NUMBER_OF_CARDS_PER_LINE;

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: SUBCONTAINER_WIDTH,
  },
  smallSubcontainer: {
    justifyContent: 'center',
  },
  projectCard: {
    height: PROJECT_CARD_HEIGHT,
    width: PROJECT_CARD_WIDTH,
    margin: PROJECT_CARD_MARGIN,
  },
};
