import React, { FunctionComponent } from 'react';
import theme from '../../../../theme';
import { Styles } from '../../../../types/Styles.type';
import { getNodePositionStyle } from '../../helpers/getNodePositionStyle/getNodePositionStyle.helper';

export type Props = {
  node: Node;
  showInput: () => void;
  resetChanges: () => void;
  hasChanges?: boolean;
};

export const HoverButtons: FunctionComponent<Props> = ({
  node,
  showInput,
  resetChanges,
  hasChanges,
}) => {
  const positionStyle = getNodePositionStyle(node);

  if (!positionStyle) {
    return null;
  }

  const { top, left } = positionStyle;

  return (
    <div style={{ top, left, ...styles.container }}>
      <div style={styles.button} onClick={showInput}>
        <div style={styles.buttonLabel}>e</div>
      </div>
      {hasChanges && (
        <div
          style={{ ...styles.button, ...styles.resetButton }}
          onClick={resetChanges}
        >
          <div style={styles.buttonLabel}>r</div>
        </div>
      )}
    </div>
  );
};

const SIZE = 20;

const styles: Styles = {
  container: {
    ...theme.liveUpdaterFixedStyle,
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    ...theme.shadow,
    height: SIZE,
    width: SIZE,
    borderRadius: SIZE / 2,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  resetButton: {
    marginLeft: theme.margin,
  },
  buttonLabel: {
    ...theme.typos.smallBody,
    color: theme.colors.black,
  },
};
