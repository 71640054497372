import { gql } from '@apollo/client';

export const GET_TRANSLATION_FILE_QUERY = gql`
  query TranslationFile($id: String!, $branch: String) {
    translationFile(id: $id) {
      id
      keyValue(branch: $branch)
      keyValueChanges
    }
  }
`;
