import React, {
  CSSProperties,
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RoundButton } from '../RoundButton/RoundButton';
import { useModal } from '../Modal/hooks/useModal';
import theme from '../../theme';
import { Styles } from '../../types/Styles.type';
import type { Props as RoundButtonProps } from '../RoundButton/RoundButton';

type Props<Datum> = {
  data: Datum[];
  SelectedDatumComponent?: FunctionComponent<{ datum: Datum }>;
  DatumComponent: FunctionComponent<{ datum: Datum }>;
  onDatumClick: (datum: Datum) => void;
  selectedDatumIndex: number;
  showListBellowButton?: boolean;
  listFooter?: {
    onClick: () => void;
    Component: FunctionComponent;
  };
  modalWidth: number;
  roundButtonProps?: Omit<RoundButtonProps, 'onClick' | 'testId'>;
  style?: CSSProperties;
};

const OPTION_HEIGHT = 40;
const MODAL_PADDING_VERTICAL = theme.margin;
const MODAL_MAX_HEIGHT = 400;
const EMPTY_CONTAINER_POSITION = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export const ButtonAndListPopup = <Datum,>({
  data,
  DatumComponent,
  onDatumClick: onDatumClickProp,
  selectedDatumIndex,
  showListBellowButton,
  listFooter,
  SelectedDatumComponent: SelectedDatumComponentProp,
  modalWidth,
  roundButtonProps,
  style = {},
}: Props<Datum>) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const { isModalVisible, hideModal, showModal } = useModal();
  const [containerPosition, setContainerPosition] = useState(
    EMPTY_CONTAINER_POSITION
  );

  const setNewContainerPosition = () => {
    const boundingClientRect = containerRef?.current?.getBoundingClientRect();
    if (boundingClientRect) {
      const { top, left, width, height } = boundingClientRect;
      setContainerPosition({ top, left, width, height });
    }
  };

  useEffect(() => {
    setNewContainerPosition();
    window.addEventListener('scroll', setNewContainerPosition);
    return () => window.removeEventListener('scroll', setNewContainerPosition);
  }, [isModalVisible]);

  const onRowClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    hideModal();
  };

  const onListFooterClick: MouseEventHandler<HTMLDivElement> | undefined =
    listFooter &&
    ((e) => {
      onRowClick(e);
      listFooter.onClick();
    });

  const onDatumClick =
    (datum: Datum): MouseEventHandler<HTMLDivElement> =>
    (e) => {
      onRowClick(e);
      onDatumClickProp(datum);
    };

  const numberOfLines = data.length + (listFooter ? 1 : 0);

  let modalHeight = OPTION_HEIGHT * numberOfLines + 2 * MODAL_PADDING_VERTICAL;
  if (modalHeight > MODAL_MAX_HEIGHT) {
    modalHeight = MODAL_MAX_HEIGHT;
  }

  const modalStyle = {
    ...styles.modal,
    width: modalWidth,
    top: containerPosition.top + (containerPosition.height - modalHeight) / 2,
    left: containerPosition.left + containerPosition.width + 2 * theme.margin,
  };
  if (showListBellowButton) {
    modalStyle.top =
      containerPosition.top + containerPosition.height + 2 * theme.margin;
    modalStyle.left =
      containerPosition.left + (containerPosition.width - modalWidth) / 2;
  }

  const { Component: ListFooter } = listFooter || {};

  const SelectedDatumComponent = SelectedDatumComponentProp || DatumComponent;

  const renderDatum = (datum: Datum, index: number) => {
    const key = `${index}`;

    return (
      <div
        key={key}
        style={styles.datumContainer}
        onClick={onDatumClick(datum)}
        data-testid={`select-datum-${index}`}
      >
        <DatumComponent datum={datum} />
      </div>
    );
  };

  return (
    <div style={style} ref={containerRef}>
      <RoundButton
        {...roundButtonProps}
        onClick={showModal}
        testId="select-datum-button"
      >
        <SelectedDatumComponent datum={data[selectedDatumIndex]} />
      </RoundButton>
      {isModalVisible && (
        <>
          <div style={styles.backdrop} onClick={hideModal} />
          <div style={modalStyle}>
            {data.map(renderDatum)}
            {!!ListFooter && (
              <div
                style={styles.datumContainer}
                onClick={onListFooterClick}
                data-testid="select-footer-button"
              >
                <ListFooter />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const styles: Styles = {
  modal: {
    ...theme.shadow,
    ...theme.liveUpdaterFixedStyle,
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius,
    paddingBottom: MODAL_PADDING_VERTICAL,
    paddingTop: MODAL_PADDING_VERTICAL,
    maxHeight: 400,
    overflow: 'scroll',
  },
  datumContainer: {
    cursor: 'pointer',
    height: OPTION_HEIGHT,
    width: '100%',
    paddingLeft: 2 * MODAL_PADDING_VERTICAL,
    paddingRight: 2 * MODAL_PADDING_VERTICAL,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    ...theme.liveUpdaterFixedStyle,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};
