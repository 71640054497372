import { gql } from '@apollo/client';

export const GET_GIT_UNMERGED_GENERATED_BRANCHES_QUERY = gql`
  query GitUnmergedGeneratedBranches($slug: String!) {
    project(slug: $slug) {
      id
      devBranch
      gitUnmergedGeneratedBranches
    }
  }
`;
