import React, { FunctionComponent, useEffect } from 'react';
import { useProjectSlugContext } from '../../providers/ProjectSlugProvider/ProjectSlugProvider';
import { getGithubAuthUrl } from './helpers/getGithubAuthUrl/getGithubAuthUrl.helper';

export const GitAuthModal: FunctionComponent = () => {
  const { projectSlug, organizationSlug } = useProjectSlugContext();

  useEffect(() => {
    window.location.href = getGithubAuthUrl(organizationSlug, projectSlug);
  }, []);

  // TODO
  return <div />;
};
