import React, { FunctionComponent } from 'react';
import { SelectedTranslationFileIdProvider } from './providers/SelectedTranslationFileIdProvider/SelectedTranslationFileIdProvider';
import { GitAuthStateProvider } from './providers/GitAuthStateProvider/GitAuthStateProvider';
import { SelectedBranchProvider } from './providers/SelectedBranchProvider/SelectedBranchProvider';
import { UpdatePanel as UpdatePanelComponent, Props } from './UpdatePanel';
import { ProjectSlugProvider } from './providers/ProjectSlugProvider/ProjectSlugProvider';
import { IsUpdatingKeyValueChangesProvider } from './providers/IsUpdatingKeyValueChangesProvider.tsx/IsUpdatingKeyValueChangesProvider';

export const UpdatePanel: FunctionComponent<
  Props & { projectSlug: string; organizationSlug: string }
> = ({ projectSlug, organizationSlug, ...restOfProps }) => (
  <ProjectSlugProvider
    projectSlug={projectSlug}
    organizationSlug={organizationSlug}
  >
    <GitAuthStateProvider>
      <SelectedBranchProvider>
        <SelectedTranslationFileIdProvider>
          <IsUpdatingKeyValueChangesProvider>
            <UpdatePanelComponent {...restOfProps} />
          </IsUpdatingKeyValueChangesProvider>
        </SelectedTranslationFileIdProvider>
      </SelectedBranchProvider>
    </GitAuthStateProvider>
  </ProjectSlugProvider>
);
