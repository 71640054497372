export const ERROR_MESSAGES = {
  signIn: {
    USER_NOT_FOUND: 'auth/user-not-found',
    WRONG_PASSWORD: 'auth/wrong-password',
  },
  register: {
    EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  },
  common: {
    NO_NETWORK: 'auth/network-request-failed',
  },
};
