import React from 'react';
import type {
  CSSProperties,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react';
import theme from '../../theme';
import intl from '../../libs/intl';
import { Loader } from '../Loader/Loader';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
type InputProps = InputHTMLAttributes<HTMLInputElement>;

type Props = {
  label?: string;
  error?: string;
  multiline?: boolean;
  canHaveError?: boolean;
  inputStyle?: CSSProperties;
  isLoading?: boolean;
  dimensions?: { height: number; width: number };
} & (
  | InputHTMLAttributes<HTMLInputElement>
  | TextareaHTMLAttributes<HTMLTextAreaElement>
);

export const Input = ({
  style = {},
  label: labelProp,
  required,
  error,
  multiline,
  canHaveError,
  disabled,
  isLoading,
  dimensions,
  inputStyle = {},
  ...restOfProps
}: Props) => {
  let label;
  if (labelProp) {
    label = required
      ? labelProp
      : `${labelProp} (${intl.formatMessage({ id: 'input.optional' })})`;
  }

  const completeInputStyle = {
    ...inputStyle,
    ...(multiline ? styles.textarea : styles.input),
    ...(disabled ? styles.disabled : {}),
    ...(dimensions || {}),
  };

  return (
    <div style={style}>
      {!!label && <div style={styles.label}>{label}</div>}
      {multiline ? (
        <textarea
          {...(restOfProps as TextareaProps)}
          disabled={disabled}
          required={required}
          style={completeInputStyle}
        />
      ) : (
        <div style={styles.inputContainer}>
          <input
            {...(restOfProps as InputProps)}
            disabled={disabled}
            required={required}
            style={completeInputStyle}
          />
          {isLoading && (
            <div style={styles.loaderContainer}>
              <Loader size={3} />
            </div>
          )}
        </div>
      )}
      {canHaveError && (
        <div style={styles.errorContainer}>
          {!!error && <div style={styles.error}>{error}</div>}
        </div>
      )}
    </div>
  );
};

const INPUT_BACKGROUND_COLOR = theme.colors.lightGrey;
const INPUT_HEIGHT = 40;
export const TEXTAREA_HEIGHT = 64;

const styles: { [key in string]: CSSProperties } = {
  label: {
    ...theme.typos.body,
    color: theme.colors.black,
    marginBottom: theme.margin,
  },
  input: {
    height: INPUT_HEIGHT,
    width: '100%',
    backgroundColor: INPUT_BACKGROUND_COLOR,
    ...theme.typos.body,
    color: theme.colors.black,
    paddingLeft: 2 * theme.margin,
    paddingRight: 2 * theme.margin,
    boxSizing: 'border-box',
  },
  textarea: {
    height: TEXTAREA_HEIGHT,
    width: '100%',
    backgroundColor: INPUT_BACKGROUND_COLOR,
    ...theme.typos.body,
    color: theme.colors.black,
    paddingLeft: 2 * theme.margin,
    paddingRight: 2 * theme.margin,
    paddingTop: theme.margin,
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  errorContainer: {
    height: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  error: {
    ...theme.typos.smallBody,
    color: theme.colors.red,
  },
  disabled: {
    opacity: 0.5,
  },
  loaderContainer: {
    height: INPUT_HEIGHT,
    backgroundColor: INPUT_BACKGROUND_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 44,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};
