import { gql } from '@apollo/client';
import { GET_PROJECT_QUERY } from '../../../../../graphql/queries/project/query';

export const getDeleteTranslationFileMutationRefetchQueries = (
  projectSlug: string
) => [
  {
    query: GET_PROJECT_QUERY,
    variables: { slug: projectSlug },
  },
];

export const DELETE_TRANSLATION_FILE_MUTATION = gql`
  mutation deleteTranslationFile($id: String!) {
    deleteTranslationFile(id: $id)
  }
`;
