import { useQuery } from '@apollo/client';
import HandleErrorService from '../../../libs/HandleErrorService';
import { GET_PROJECT_QUERY } from '../graphql/queries/project/query';
import {
  GetProjectQueryData,
  GetProjectQueryVariables,
} from '../graphql/queries/project/types';
import { useProjectSlugContext } from '../providers/ProjectSlugProvider/ProjectSlugProvider';
import { useSelectedBranchContext } from '../providers/SelectedBranchProvider/SelectedBranchProvider';

export const useProjectQuery = (skip = false) => {
  const { projectSlug } = useProjectSlugContext();
  const { selectedBranch } = useSelectedBranchContext();

  const { data, loading } = useQuery<
    GetProjectQueryData,
    GetProjectQueryVariables
  >(GET_PROJECT_QUERY, {
    variables: { slug: projectSlug, branch: selectedBranch || undefined },
    onError: HandleErrorService.showErrorToast,
    skip,
  });

  return {
    project: data?.project,
    isFetchingProject: loading,
  };
};
