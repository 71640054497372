import { gql } from '@apollo/client';

export const UPDATE_TRANSLATION_FILE_MUTATION = gql`
  mutation updateTranslationFile($input: UpdateTranslationFileInput!) {
    updateTranslationFile(input: $input) {
      id
      keyValue
      countryCode
      keyValueChanges
    }
  }
`;
