import { KeyValue } from 'louv-converter';

export const getUpdatedKeyValue = (
  keyValueString: string,
  keyValueChangesString?: string
): KeyValue => {
  const updatedKeyValue = { ...JSON.parse(keyValueString) };
  const keyValueChanges =
    keyValueChangesString && JSON.parse(keyValueChangesString);

  if (keyValueChanges) {
    Object.keys(keyValueChanges).forEach((key) => {
      if (updatedKeyValue[key] !== undefined) {
        updatedKeyValue[key] = keyValueChanges[key];
      }
    });
  }

  return updatedKeyValue;
};
