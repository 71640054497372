import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import HandleErrorService from '../../../libs/HandleErrorService';
import { UPDATE_GIT_TOKEN_MUTATION } from '../graphql/mutations/updateGitToken/mutation';
import type {
  UpdateGitTokenMutationData,
  UpdateGitTokenMutationVariables,
} from '../graphql/mutations/updateGitToken/types';

export const useUpdateGitToken = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const githubCode = searchParams.get('code');

  const [updateGitToken, { loading: isUpdatingGitToken }] = useMutation<
    UpdateGitTokenMutationData,
    UpdateGitTokenMutationVariables
  >(UPDATE_GIT_TOKEN_MUTATION, {
    onError: HandleErrorService.showErrorToast,
  });

  useEffect(() => {
    if (githubCode) {
      searchParams.delete('code');
      setSearchParams(searchParams);
      updateGitToken({ variables: { input: { githubCode } } });
    }
  }, []);

  return {
    isUpdatingGitToken,
  };
};
