import React, { FunctionComponent } from 'react';
import type { Styles } from '../../types/Styles.type';
import { SignInForm } from '../../components';

export const SignIn: FunctionComponent = () => (
  <div style={styles.container}>
    <SignInForm />
  </div>
);

const styles: Styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
};
