import React, { FunctionComponent, MouseEventHandler } from 'react';
import type { Styles } from '../../types/Styles.type';
import theme from '../../theme';

type Props = {
  isVisible: boolean;
  hide?: () => void;
};

export const Modal: FunctionComponent<Props> = ({
  isVisible,
  hide,
  children,
}) => {
  if (!isVisible) {
    return null;
  }

  const onContentContainerClick: MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  return (
    <div style={styles.container} onClick={hide} data-testid="modal-container">
      <div
        style={styles.contentContainer}
        onClick={onContentContainerClick}
        data-testid="modal-content-container"
      >
        {hide && (
          <div
            onClick={hide}
            style={styles.closeButton}
            data-testid="modal-close-button"
          >
            <img
              src={`${process.env.REACT_APP_FRONT_URL}/svgs/cross.svg`}
              style={styles.closeIcon}
              alt=""
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

const CLOSE_ICON_SIZE = 20;
const CLOSE_BUTTON_PADDING = 2 * theme.margin;

const styles: Styles = {
  container: {
    ...theme.liveUpdaterFixedStyle,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.black05,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 500,
    padding: 4 * theme.margin,
    maxHeight: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius,
    boxSizing: 'border-box',
    overflow: 'scroll',
  },
  closeButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    padding: CLOSE_BUTTON_PADDING,
    marginTop: -CLOSE_BUTTON_PADDING,
    marginRight: -CLOSE_BUTTON_PADDING,
    marginBottom: 4 * theme.margin,
  },
  closeIcon: {
    height: CLOSE_ICON_SIZE,
    width: CLOSE_ICON_SIZE,
  },
};
