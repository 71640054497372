import { useMutation } from '@apollo/client';
import { string } from 'yup';
import { useDebouncedMutate } from '../../../libs/apollo/hooks/useDebouncedMutate';
import HandleErrorService from '../../../libs/HandleErrorService';
import intl from '../../../libs/intl';
import { IS_EMAIL_AVAILABLE_MUTATION } from '../graphql/mutations/isEmailAvailable/mutation';
import {
  IsEmailAvailableMutationData,
  IsEmailAvailableMutationVariables,
} from '../graphql/mutations/isEmailAvailable/types';
import { IS_NICKNAME_AVAILABLE_MUTATION } from '../graphql/mutations/isNicknameAvailable/mutation';
import {
  IsNicknameAvailableMutationData,
  IsNicknameAvailableMutationVariables,
} from '../graphql/mutations/isNicknameAvailable/types';
import { IS_ORGANIZATION_NAME_AVAILABLE_MUTATION } from '../graphql/mutations/isOrganizationNameAvailable/mutation';
import {
  IsOrganizationNameAvailableMutationData,
  IsOrganizationNameAvailableMutationVariables,
} from '../graphql/mutations/isOrganizationNameAvailable/types';
import { FormValues } from './type';

export const INITIAL_FORM_VALUES: FormValues = {
  email: '',
  nickname: '',
  password: '',
  confirmedPassword: '',
};

const PASSWORD_MIN_LENGTH = 7;

export const useValidate = () => {
  const [isEmailAvailableMutate] = useMutation<
    IsEmailAvailableMutationData,
    IsEmailAvailableMutationVariables
  >(IS_EMAIL_AVAILABLE_MUTATION, {
    onError: HandleErrorService.showErrorToast,
  });
  const debouncedIsEmailAvailableMutate = useDebouncedMutate(
    isEmailAvailableMutate
  );
  const [isNicknameAvailableMutate] = useMutation<
    IsNicknameAvailableMutationData,
    IsNicknameAvailableMutationVariables
  >(IS_NICKNAME_AVAILABLE_MUTATION, {
    onError: HandleErrorService.showErrorToast,
  });
  const debouncedIsNicknameAvailableMutate = useDebouncedMutate(
    isNicknameAvailableMutate
  );
  const [isOrganizationNameAvailableMutate] = useMutation<
    IsOrganizationNameAvailableMutationData,
    IsOrganizationNameAvailableMutationVariables
  >(IS_ORGANIZATION_NAME_AVAILABLE_MUTATION, {
    onError: HandleErrorService.showErrorToast,
  });
  const debouncedIsOrganizationNameAvailableMutate = useDebouncedMutate(
    isOrganizationNameAvailableMutate
  );

  const getValidate =
    (isAnOrganization: boolean) =>
    async ({
      email,
      nickname,
      password,
      confirmedPassword,
      organizationName,
    }: FormValues) => {
      const errors: {
        email?: string;
        nickname?: string;
        password?: string;
        confirmedPassword?: string;
        organizationName?: string;
      } = {};

      if (!(await string().required().isValid(email))) {
        errors.email = intl.formatMessage({ id: 'form.errors.required' });
      } else if (!(await string().email().isValid(email))) {
        errors.email = intl.formatMessage({ id: 'form.errors.invalidEmail' });
      } else {
        const res = await debouncedIsEmailAvailableMutate({
          variables: { email },
        });
        if (!res?.data?.isEmailAvailable) {
          errors.email = intl.formatMessage({
            id: 'form.errors.emailAlreadyInUse',
          });
        }
      }

      if (!(await string().required().isValid(nickname))) {
        errors.nickname = intl.formatMessage({ id: 'form.errors.required' });
      } else {
        const res = await debouncedIsNicknameAvailableMutate({
          variables: { nickname },
        });
        if (!res?.data?.isNicknameAvailable) {
          errors.nickname = intl.formatMessage({
            id: 'form.errors.nicknameAlreadyInUse',
          });
        }
      }

      if (!(await string().required().isValid(password))) {
        errors.password = intl.formatMessage({ id: 'form.errors.required' });
      } else if (password?.length < PASSWORD_MIN_LENGTH) {
        errors.password = intl.formatMessage(
          {
            id: 'form.errors.passwordMinLength',
          },
          { minLength: PASSWORD_MIN_LENGTH }
        );
      } else if (confirmedPassword !== password) {
        errors.confirmedPassword = intl.formatMessage({
          id: 'form.errors.confirmedPassword',
        });
      }

      if (isAnOrganization) {
        if (!(await string().required().isValid(organizationName))) {
          errors.organizationName = intl.formatMessage({
            id: 'form.errors.required',
          });
        } else {
          const res = await debouncedIsOrganizationNameAvailableMutate({
            variables: { name: organizationName as string },
          });
          if (!res?.data?.isOrganizationNameAvailable) {
            errors.organizationName = intl.formatMessage({
              id: 'form.errors.organizationNameAlreadyInUse',
            });
          }
        }
      }

      return errors;
    };

  return getValidate;
};
