import { gql } from '@apollo/client';

export const UPDATE_DEV_BRANCH_MUTATION = gql`
  mutation updateDevBranch($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      devBranch
    }
  }
`;
