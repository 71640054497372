import { object, string } from 'yup';
import intl from '../../libs/intl';
import { ErrorHandler } from '../../libs/HandleErrorService';
import { ERROR_MESSAGES } from '../../libs/authentication/errors';

export const INITIAL_FORM_VALUES = {
  email: '',
  password: '',
};

export const VALIDATION_SCHEMA = object().shape({
  email: string()
    .email(intl.formatMessage({ id: 'form.errors.invalidEmail' }))
    .required(intl.formatMessage({ id: 'form.errors.required' })),
  password: string().required(
    intl.formatMessage({ id: 'form.errors.required' })
  ),
});

export const errorHandler: ErrorHandler = (error) => {
  if (error instanceof Error) {
    const message = error?.message || '';

    if (message.includes(ERROR_MESSAGES.signIn.USER_NOT_FOUND)) {
      return intl.formatMessage({ id: 'signIn.userNotFound' });
    }

    if (message.includes(ERROR_MESSAGES.signIn.WRONG_PASSWORD)) {
      return intl.formatMessage({ id: 'signIn.wrongPassword' });
    }
  }

  return null;
};
