import React, { FunctionComponent, CSSProperties } from 'react';
import { KeyValue } from 'louv-converter';
import type { Styles } from '../../types/Styles.type';
import theme from '../../theme';
import { Pagination } from './components/Pagination/Pagination';
import { usePagination } from './components/Pagination/hooks/usePagination';
import { Headers } from './components/Headers/Headers';
import { filterData } from './components/Headers/helpers/filterData/filterData.helper';
import { useFilters } from './components/Headers/hooks/useFilters';
import { ValueCell } from './components/ValueCell/ValueCell';
import { KeyCell } from './components/KeyCell/KeyCell';

type Props = {
  style?: CSSProperties;
  keyValue: KeyValue;
  onChange: (key: string, value: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const KeyValueTable: FunctionComponent<Props> = ({
  keyValue,
  onChange,
  style = {},
}) => {
  const filters = useFilters();
  const pagination = usePagination();

  const filteredData = filterData({
    data: keyValue,
    filters,
  });
  const filteredDataArray = Object.entries(filteredData);
  const { pageIndex, pageSize } = pagination;
  const paginatedDataArray = filteredDataArray.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize
  );

  const onValueCellChange = (key: string) => (value: string) =>
    onChange(key, value);

  return (
    <div style={{ ...style, ...styles.container }}>
      <Headers filters={filters} style={styles.filters} />
      {paginatedDataArray.map(([key, value], index) => (
        <div key={key} style={styles.rowContainer}>
          <KeyCell label={key} />
          <ValueCell
            value={value}
            onChange={onValueCellChange(key)}
            style={{
              ...styles.valueCell,
              ...(!index ? styles.firstValueCell : {}),
            }}
          />
        </div>
      ))}
      <Pagination
        style={styles.pagination}
        dataSize={filteredDataArray.length}
        {...pagination}
      />
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.widths.subcontainer,
  },
  filters: {
    marginBottom: 2 * theme.margin,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pagination: {
    justifyContent: 'flex-end',
    marginTop: theme.margin,
  },
  valueCell: {
    borderStyle: 'solid',
    borderColor: theme.colors.grey,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  firstValueCell: {
    borderTopWidth: 1,
  },
};
