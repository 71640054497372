import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
} from 'react';

const IsUpdatingKeyValueChangesContext = createContext<{
  isUpdatingKeyValueChanges: boolean;
  setIsUpdatingKeyValueChanges: (isVisible: boolean) => void;
}>({
  isUpdatingKeyValueChanges: false,
  setIsUpdatingKeyValueChanges: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const IsUpdatingKeyValueChangesProvider: FunctionComponent = ({
  children,
}) => {
  const [isUpdatingKeyValueChanges, setIsUpdatingKeyValueChanges] =
    useState<boolean>(false);

  return (
    <IsUpdatingKeyValueChangesContext.Provider
      value={{
        isUpdatingKeyValueChanges,
        setIsUpdatingKeyValueChanges,
      }}
    >
      {children}
    </IsUpdatingKeyValueChangesContext.Provider>
  );
};

export const useIsUpdatingKeyValueChangesContext = () =>
  useContext(IsUpdatingKeyValueChangesContext);
