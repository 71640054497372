import React, { FunctionComponent, CSSProperties } from 'react';
import type { Styles } from '../../../../types/Styles.type';
import theme from '../../../../theme';
import { StepNumber, STEP_NUMBER_SIZE } from '../StepNumber/StepNumber';

type Props = {
  stepIndex: number; // starting at 0 (not 1)
  setStepIndex: (index: number) => void;
  numberOfSteps: number;
  style?: CSSProperties;
};

export const ProgressBar: FunctionComponent<Props> = ({
  stepIndex,
  setStepIndex,
  numberOfSteps,
  style = {},
}) => {
  const onStepNumberClick = (index: number) => () => {
    if (index < stepIndex) {
      setStepIndex(index);
    }
  };

  const percentage = numberOfSteps
    ? ((stepIndex + 0.5) * 100) / numberOfSteps
    : 0;

  return (
    <div style={{ ...style, ...styles.container }}>
      <div style={{ ...styles.subcontainer, width: `${percentage}%` }} />
      <div style={styles.stepNumbersContainer}>
        {new Array(numberOfSteps).fill(0).map((_, index) => {
          const key = `${index}`;

          return (
            <StepNumber
              key={key}
              stepIndex={index}
              isCompleted={index <= stepIndex}
              onClick={onStepNumberClick(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

const styles: Styles = {
  container: {
    height: 5,
    width: '100%',
    borderRadius: theme.borderRadius,
    borderStyle: 'solid',
    borderColor: theme.colors.grey,
    borderWidth: 1,
    position: 'relative',
  },
  subcontainer: {
    backgroundColor: theme.colors.black,
    borderRadius: theme.borderRadius,
    height: '100%',
    transition: 'width 1s',
  },
  stepNumbersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'absolute',
    left: 0,
    right: 0,
    top: -STEP_NUMBER_SIZE / 2,
  },
};
