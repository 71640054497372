import { gql } from '@apollo/client';

export const GET_PROJECT_QUERY = gql`
  query Project($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      slug
      name
      repoUrl
      devBranch
    }
  }
`;
