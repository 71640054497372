import React, { FunctionComponent } from 'react';
import { KeyValue } from 'louv-converter';
import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader';
import { Header } from './components/Header/Header';
import { useGitAuthStateContext } from './providers/GitAuthStateProvider/GitAuthStateProvider';
import { GitAuthModal } from './components/GitAuthModal/GitAuthModal';
import { SelectDevBranchModal } from './components/SelectDevBranchModal/SelectDevBranchModal';
import { ManageTranslationFilesModal } from './components/ManageTranslationFilesModal/ManageTranslationFilesModal';
import { useUpdateGitToken } from './hooks/useUpdateGitToken';
import { useProjectQuery } from './hooks/useProjectQuery';
import { Footer } from './components/Footer/Footer';
import { TableComponentWrapper } from './components/TableComponentWrapper/TableComponentWrapper';
import { useIsLiveUpdaterContext } from '../../providers/IsLiveUpdaterProvider/IsLiveUpdaterProvider';
import { useIsUpdatePanelVisibleContext } from '../../providers/IsUpdatePanelVisibleProvider/IsUpdatePanelVisibleProvider';
import { Styles } from '../../types/Styles.type';
import theme from '../../theme';

export type Props = {
  TableComponent: FunctionComponent<{
    keyValue: KeyValue;
    initialKeyValue: KeyValue;
    onChange: (key: string, value: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  }>;
};

export const UpdatePanel: FunctionComponent<Props> = ({ TableComponent }) => {
  const { gitAuthState } = useGitAuthStateContext();
  const { isUpdatingGitToken } = useUpdateGitToken();
  const isLiveUpdater = useIsLiveUpdaterContext();
  const { isUpdatePanelVisible } = useIsUpdatePanelVisibleContext();
  const { project, isFetchingProject } = useProjectQuery(isUpdatingGitToken);

  if ((isFetchingProject || isUpdatingGitToken) && !project) {
    return <FullScreenLoader />;
  }

  if (!gitAuthState.isTokenValid) {
    return <GitAuthModal />;
  }

  if (!project) {
    return null;
  }

  if (!project.devBranch) {
    return <SelectDevBranchModal isVisible />;
  }

  if (!project.translationFiles.length) {
    return <ManageTranslationFilesModal isVisible />;
  }

  return (
    <>
      <Header />
      <TableComponentWrapper TableComponent={TableComponent} />
      {(!isLiveUpdater || isUpdatePanelVisible) && (
        <Footer
          style={isLiveUpdater ? styles.liveUpdaterFooter : styles.footer}
        />
      )}
    </>
  );
};

const styles: Styles = {
  footer: {
    position: 'fixed',
    right: theme.pagePaddings.horizontal,
    bottom: theme.pagePaddings.vertical,
  },
  liveUpdaterFooter: {
    ...theme.liveUpdaterFixedStyle,
    right: theme.pagePaddings.liveUpdater.horizontal,
    bottom: theme.pagePaddings.liveUpdater.vertical,
  },
};
